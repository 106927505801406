export const monitoringCycleConstants = {
    // GET MONITORING CYCLE LOGS
    GETMONITORINGCYCLELOGS_REQUEST: "MONITORINGCYCLE_GET_LOG_REQUEST",
    GETMONITORINGCYCLELOGS_SUCCESS: "MONITORINGCYCLE_GET_LOG_SUCCESS",
    GETMONITORINGCYCLELOGS_FAILURE: "MONITORINGCYCLE_GET_LOG_FAILURE",

    // GET ALL SITE LOGS
    GET_ALL_SITE_LOGS_REQUEST: "ALL_SITE_GET_LOG_REQUEST",
    GET_ALL_SITE_LOGS_SUCCESS: "ALL_SITE_GET_LOG_SUCCESS",
    GET_ALL_SITE_LOGS_FAILURE: "ALL_SITE_GET_LOG_FAILURE",

    // create new monitoring cycle
    CREATE_MONITORING_CYCLE_REQUEST: 'CREATE_MONITORING_CYCLE_REQUEST',
    CREATE_MONITORING_CYCLE_SUCCESS: 'CREATE_MONITORING_CYCLE_SUCCESS',
    CREATE_MONITORING_CYCLE_FAILURE: 'CREATE_MONITORING_CYCLE_FAILURE',

    // GET DUPLICATE RECORDS
    CHECK_DUPLICATE_REQUEST: 'CHECK_DUPLICATE_REQUEST',
    CHECK_DUPLICATE_SUCCESS: 'CHECK_DUPLICATE_SUCCESS',
    CHECK_DUPLICATE_FAILURE: 'CHECK_DUPLICATE_FAILURE',

    GET_FUNDER_LIST_REQUEST: 'GET_FUNDER_LIST_REQUEST',
    GET_FUNDER_LIST_SUCCESS: 'GET_FUNDER_LIST_SUCCESS',
    GET_FUNDER_LIST_ERROR: 'GET_FUNDER_LIST_ERROR',

    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILURE: 'DELETE_FAILURE',

    DOWNLOAD_REQUEST: 'DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILURE: 'DOWNLOAD_FAILURE',

    CHANGE_MONITORING_CYCLE_LOGS: 'CHANGE_MONITORING_CYCLE_LOGS',

    CHANGE_CHECKED_STATE: 'CHANGE_CHECKED_STATE',

    CHANGE_ALL_SITE_LOGS: 'CHANGE_ALL_SITE_LOGS',

    CHANGE_TEMP_MONITORING_CYCLE_LOGS: 'CHANGE_TEMP_MONITORING_CYCLE_LOGS',

    CLEAR_DUPLICATE_FLAG: 'CLEAR_DUPLICATE_FLAG',

    CHANGE_PAGE_DETAILS: 'CHANGE_PAGE_DETAILS'
}