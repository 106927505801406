import { searchConstants } from '../constants';


const initialState = {
    maintenance: {
        sFields: [],
        sValues: []
    },
    users:{
        sFields:[],
        sValues:[]
    }
};

export function search(state = initialState, action) {
    switch (action.type) {
        case searchConstants.PROJECTS_SEARCH:
            return {
                ...state,
                projects: {
                    ...action.data
                }
            };
        case searchConstants.LOAN_PROJECT_SEARCH:
            return {
                ...state,
                loanProjects: {
                    ...action.data
                }
            };
        // LOAN_ACTIVITY_SEARCH
        case searchConstants.LOAN_ACTIVITY_SEARCH:
            return {
                ...state,
                contractActivity: {
                    ...action.data
                }
            };
        case searchConstants.REPORT_ACTIVITY_SEARCH:
            return{
                ...state,
                reportActivity:{
                    ...action.data
                }
            };

        case searchConstants.CONTRACT_SEARCH:
            return {
                ...state,
                contract: {
                    ...action.data
                }
            };
        case searchConstants.REPORT_LIST_SEARCH:
            return {
                ...state,
                reportList: {
                    ...action.data
                }
            };
        // REPORT_LIST_SEARCH

        case searchConstants.USER_PROJECTS_SEARCH:
            return {
                ...state,
                userProjects: {
                    ...action.data
                }
            };
        case searchConstants.CLEAR_USER_SEARCH:{
            return{
                ...state,
                users:{
                    sFields:[],
                    sValues:[]
                }
            }
        };
        case searchConstants.ARCHIVES_SEARCH:
            return {
                ...state,
                archives: {
                    ...action.data
                }
            };
        case searchConstants.SITES_SEARCH:
            return {
                ...state,
                sites: {
                    ...action.data
                }
            };
        case searchConstants.SITESLIST_SEARCH:
            return {
                ...state,
                sitesList: {
                    ...action.data
                }
            };
        case searchConstants.BUILDINGS_SEARCH:
            return {
                ...state,
                buildings: {
                    ...action.data
                }
            };
        case searchConstants.BUILDINGSLIST_SEARCH:
            return {
                ...state,
                buildingsList: {
                    ...action.data
                }
            };
        case searchConstants.UNITS_SEARCH:
            return {
                ...state,
                units: {
                    ...action.data
                }
            };
        case searchConstants.UNITSLIST_SEARCH:
            return {
                ...state,
                unitsList: {
                    ...action.data
                }
            };
        case searchConstants.OCCUPANTS_SEARCH:
            return {
                ...state,
                occupants: {
                    ...action.data
                }
            };
        case searchConstants.ORGANIZATIONS_SEARCH:
            return {
                ...state,
                organizations: {
                    ...action.data
                }
            };
        case searchConstants.USERS_SEARCH:
            return {
                ...state,
                users: {
                    ...action.data
                }
            };
        case searchConstants.CONTACT_SEARCH:
            return{
                ...state,
                contact:{
                    ...action.data
                }
            }
        case searchConstants.COMPLIANCE_SEARCH:
            return {
                ...state,
                compliance: {
                    ...action.data
                }
            };
        case searchConstants.PMDASHBOARD_SEARCH:
            return {
                ...state,
                pmdashboard: {
                    ...action.data
                }
            };
        case searchConstants.PMDASHBOARDHO_SEARCH:
            return {
                ...state,
                pmdashboardHO: {
                    ...action.data
                }
            };
        case searchConstants.PMEFFECTIVE_SEARCH:
            return {
                ...state,
                pmeffective: {
                    ...action.data
                }
            };
        case searchConstants.DASHBOARDNEEDSACTION_SEARCH:
            return {
                ...state,
                dashboard: {
                    ...action.data
                }
            };
        case searchConstants.MAILTEMPLATE_SEARCH:
            return {
                ...state,
                mailTemplate: {
                    ...action.data
                }
            };
        case searchConstants.VERIFY_DATA_MAINTENANCE:
            return {
                ...state,
                maintenance: {
                    ...action.data
                }
            };
        case searchConstants.TABLE1_IMPORT_LOG:
        return {
            ...state,
            table1XMLLog: {
                ...action.data
            }
        };
        case searchConstants.MONITORING_CYCLE_SEARCH:
            return {
                ...state,
                monitoringCycle: {
                    ...action.data
                }
            }
        case searchConstants.SITE_ALL_SEARCH: 
            return {
                ...state,
                siteAll: {
                    ...action.data
                }
            }
        case searchConstants.CLEAR_SEARCH:
            return initialState;
        default:
            return state;
    }
}