export const searchConstants = {
    PROJECTS_SEARCH: 'PROJECTS_SEARCH',
    ARCHIVES_SEARCH: 'ARCHIVES_SEARCH',
    SITES_SEARCH: 'SITES_SEARCH',
    SITESLIST_SEARCH: 'SITESLIST_SEARCH',
    BUILDINGS_SEARCH: 'BUILDINGS_SEARCH',
    BUILDINGSLIST_SEARCH: "BUILDINGSLIST_SEARCH",
    UNITS_SEARCH: 'UNITS_SEARCH',
    UNITSLIST_SEARCH: 'UNITSLIST_SEARCH',
    OCCUPANTS_SEARCH: 'OCCUPANTS_SEARCH',
    ORGANIZATIONS_SEARCH: 'ORGANIZATIONS_SEARCH',
    USERS_SEARCH: 'USERS_SEARCH',
    CONTACT_SEARCH: 'CONTACT_SEARCH',
    COMPLIANCE_SEARCH: "COMPLIANCE_SEARCH",
    CLEAR_SEARCH: "CLEAR_SEARCH",
    PMDASHBOARD_SEARCH: "PMDASHBOARD_SEARCH",
    PMDASHBOARDHO_SEARCH: "PMDASHBOARDHO_SEARCH",
    PMEFFECTIVE_SEARCH: "PMEFFECTIVE_SEARCH",
    DASHBOARDNEEDSACTION_SEARCH: "DASHBOARDNEEDSACTION_SEARCH",
    USER_PROJECTS_SEARCH: "USER_PROJECTS_SEARCH",
    MAILTEMPLATE_SEARCH: "MAILTEMPLATE_SEARCH",
    VERIFY_DATA_MAINTENANCE: "VERIFY_DATA_MAINTENANCE",
    LOAN_PROJECT_SEARCH: "LOAN_PROJECT_SEARCH",
    CONTRACT_SEARCH:"CONTRACT_SEARCH",
    REPORT_LIST_SEARCH : "REPORT_LIST_SEARCH",
    LOAN_ACTIVITY_SEARCH : "LOAN_ACTIVITY_SEARCH",
    REPORT_ACTIVITY_SEARCH:"REPORT_ACTIVITY_SEARCH",
    CLEAR_USER_SEARCH: "CLEAR_USER_SEARCH",
    TABLE1_IMPORT_LOG: "XML_LOG_SEARCH",
    MONITORING_CYCLE_SEARCH: "MONITORING_CYCLE_SEARCH",
    SITE_ALL_SEARCH: "SITE_ALL_SEARCH"
};
