import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Import Form Elements
import ValidatorForm from "../../../helpers/fieldValidations";
import TextBox from '../../../components/TextBox/TextBox';
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import AutoCompleteMultiMUI from '../../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI';
import TableComponent from '../../../components/Table/TableComponent';
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helpers
import { history, getUser } from '../../../helpers';
import { getUserId, getUserOrgId, getUserName, getOrgType } from '../../../helpers';

// Import Constants
import { configurationConstants } from '../../../helpers/appConstants';

// Import Actions
import { confActions } from '../../../actions/configuration';
import { projectsActions } from '../../../actions/projects';

//import services
import {  incomeRentService } from '../../../services';

const address = {
    line: '',
    city: null,
    state: null,
    postalCode: ''
};

/**
 * Project General container
 * @class General
 * @extends Component
 */
class General extends Component {

    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        const { org } = getUser();
        this.state = {
            orgDet: org,
            tab: 0,
            name: '',
            prevName: '',
            totalSites: '',
            totalBidgs: '',
            totalUnits: '',
            desc: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            isConfidentialFlag: false,
            org: {
                id: null,
                name: '',
                address: {
                    ...address
                },
            },
            user: {
                id: null,
                name: '',
                phone: '',
                email: '',
                address: {
                    ...address
                },
            },
            isApplied: false,
            confirmPopup: false,
            readOnlyFunders: [],
            claimTrue: false,
            duplicateProjects: [],
            duplicateProjectId: null,
            projectId:0                 //used only for the reference check

        };

        this.userId = getUserId();
        this.userOrgId = getUserOrgId();
        this.loggedUserName = getUserName();
        this.userType = getOrgType();
    }

    /**
     * Component Did Mount Event
     * 
     * [1]  -   Get States
     * [2]  -   Get Cities
     * [3]  -   Get organizations
     * [4]  -   Get Read-only funders
     * [5]  -   Get project by ID
     */
    componentDidMount() {
        const id = this.props.projectId || this.props.copyId;
        // [1]
        this.props.getConfiguration(configurationConstants.state);
        // [2]
        this.props.getConfiguration(configurationConstants.cities);
        // [3]
        this.props.getAllOrgs('', 'projects');
        // [4]
        this.props.getReadOnlyOrg();
        // [5]
        if (id) {
            this.props.getById(id);
        }
    }

    /**
     * Component Will Receive Props
     * @param {*} newProps 
     * 
     * [1]  -   Get project by ID
     * [2]  -   Set project details to state
     */
    UNSAFE_componentWillReceiveProps(newProps) {
        const { details, projectId, copyId } = newProps;
        //-----------------INCOME RENT CRON RUN--------------------------
        // if(this.state.projectId != projectId){
        //     for(let i=0;i<1;i++){
        //     incomeRentService.triggerIncomeRent(projectId,0,0,0,0,0)
        //     console.log('cron')
        //     this.setState({projectId})
        //     }
        // }

    //---------------------------------------------------------------
        // [1]
        if (projectId !== this.props.projectId) {
            this.props.getById(projectId);
            this.setState({ isApplied: false });
        }
        // [2]
        const { data } = details;
        let project = data;
        const id = projectId || copyId;
        if (project && id) {
            if (id && !this.state.isApplied) {
                let projectDetails = { ...project };
                projectDetails.owners.user_id = projectDetails.owners.user_id || { address: { ...address } };
                let contactName = `${projectDetails.owners.user_id.firstName} ${projectDetails.owners.user_id.lastName}`;
                contactName = projectDetails.owners.user_id.isActive === false ? `*${contactName}` : contactName;
                this.setState({
                    tab: 0,
                    name: projectId ? projectDetails.name : '',
                    prevName: projectId ? projectDetails.name : '',
                    totalSites: projectId ? projectDetails.sites : '',
                    totalBidgs: projectId ? projectDetails.buildings : '',
                    totalUnits: projectId ? projectDetails.units : '',
                    isConfidentialFlag: projectDetails.isConfidentialFlag,
                    desc: projectDetails.desc,
                    address: projectDetails.address.line,
                    city: projectDetails.address.city,
                    state: projectDetails.address.state,
                    zipcode: projectDetails.address.zipcode,
                    org: {
                        ...this.state.org,
                        ...projectDetails.owners.org_id,
                        id: projectDetails.owners.org_id ? projectDetails.owners.org_id._id : null,
                        value: projectDetails.owners.org_id ? projectDetails.owners.org_id._id : null,
                        label: projectDetails.owners.org_id ? projectDetails.owners.org_id.name : null,
                    },
                    user: {
                        ...projectDetails.owners.user_id,
                        id: projectDetails.owners.user_id._id,
                        value: projectDetails.owners.user_id._id,
                        name: contactName,
                        label: contactName,
                    },
                    isApplied: true,
                    readOnlyFunders: projectDetails.readOnlyFunders ? projectDetails.readOnlyFunders : []
                });
            }
        }
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle City autocomplete
     * @param {*} selectedOption 
     */
    _handleAutoComplete = (selectedOption) => {
        this.setState({ city: selectedOption.value });
    }
    /**
     * Handle state autocomplete
     * @param {*} selectedOption 
     */
    handleAutoCompleteState = (selectedOption) => {
        this.setState({ state: selectedOption.value });
    }
    /**
    * Handle read-only funder autocomplete
    * @param {*} selectedOption 
    */
    _handleROFAutoComplete = (selectedOption) => {
        this.setState({ readOnlyFunders: selectedOption });
        this.props.getReadOnlyOrg();
    }
    /**
     * Handle organization autocomplete
     * @param {*} selectedOption 
     * 
     * [1]  -   If selectedOption is empty, set empty org
     * [2]  -   else set org details
     * [3]  -   Fetech org details by ID
     */
    handleAutoCompleteOrg = (selectedOption) => {
        // [1]
        if (selectedOption.length === 0) {
            this.setState({ org: { id: null, name: '', address: { ...address } }, user: { id: null, name: '', value: '', label: '', address: { ...address } } });
        } else {
            // [2]
            this.setState({ org: { ...selectedOption }, user: { id: null, name: '', value: '', label: '', address: { ...address } } });
            // [3]
            if (selectedOption._id) {
                this.props.getAllUsersInOrg(selectedOption._id);
            }
        }
    }
    /**
     * Handle user autocomplete
     * @param {*} selectedOption 
     * 
     * [1]  -   If selectedOption is empty, set empty user
     * [2]  -   else set user details
     */
    handleAutoCompleteUser = (selectedOption) => {
        // [1]
        if (selectedOption.length === 0) {
            this.setState({ user: { id: null, name: '', value: '', label: '', address: { ...address } } });
        } else {
            // [2]
            this.setState({ user: { ...selectedOption } });
        }
    }
    /**
     *  Handle Form Submit Event
     * @param {*} e 
     * 
     * [1]  -   Check if the form is New / update
     * [2]  -   If the form is new, check if the project already exists
     * [3]  -   Check, if the project is own organization project then allow to create duplicate on confirmation popup
     * [4]  -   Check, if the project is other organization project then allow to claim the projects, restrict the duplicate project
     * [5]  -   If duplicate project length is equal to 1
     * [6]  -   If duplicate project length is greater that 1
     * [7]  -   Save the form if duplicate projects length is 0
     * [8]  -   Save the form if it is new
     */
    handleSubmit = (e) => {
        // [1]
        if (this.props.projectId === null) {
            this.props.isProjectNameExists({ name: this.state.name, id: this.props.projectId, address: this.state.address, city: this.state.city, state: this.state.state, zipcode: this.state.zipcode }, (projects) => {
                // [2]
                if (projects.length > 0) {
                    // [3]
                    if (projects[0].selfOrgProjects) {
                        this.setState({
                            confirmPopup: true
                        })
                    } else if (projects[0].otherOrgProjects) {
                        // [4]
                        let duplicateProjects = projects[0].projects;
                        // [5]
                        if (projects[0].projects.length > 0 && projects[0].projects.length === 1) {
                            this.setState({
                                claimTrue: true,
                                duplicateProjectId: projects[0].projects[0]._id
                            })
                        } else if (projects[0].projects.length > 1) {
                            // [6]
                            this.setState({
                                claimTrue: true,
                                duplicateProjects: duplicateProjects.map(elem => {
                                    delete elem.selfOrgProjects;
                                    delete elem.otherOrgProjects;
                                    return elem
                                })
                            })
                        }
                    }
                } else {
                    // [7]
                    this._saveForm()
                }
            })
        }
        else {
            // [8]
            this._saveForm()
        }

    };
    /**
     * On Popup confirmation if duplicate project length equal to 1
     */
    onPopupOk = () => {
        const { duplicateProjectId } = this.state;
        this.setState({ claimTrue: false })
        history.replace(`/projects/${duplicateProjectId}/funders/new`);
    }

    /**
     * Save form
     * 
     * [1]  -   No need to chec copy id
     */
    _saveForm() {
        let creator = {
            userId: this.userId,
            userName: this.loggedUserName,
            userOrgId: this.userOrgId
        }
        let generalData = { ...this.state, creator };
        let isNameChanged = this.state.name != this.state.prevName;
        const selectedROF = generalData.readOnlyFunders.map(item => item.value);
        generalData['readOnlyFunders'] = selectedROF;

        // [1]
        if (this.props.projectId) {
            this.props.handleFormSaveActionClick(this.props.projectId, {...generalData,isNameChanged});
        } else {
            this.props.handleFormSaveActionClick(generalData);
        }

        this.setState({
            prevName: this.state.name
        })
    }
    /**
   * Bind other associated organizations
   */
    bindOtherAssociatedOrg() {
        const { details: { readOnlyOrg } } = this.props;
        return (
            <React.Fragment>
                {readOnlyOrg.map((item, index) =>
                    <FormControlLabel key={'formCtrlLable' + index}
                        control={
                            <Checkbox
                                checked={'checkedA'}
                                value="checkedA"
                                key={'formCtrlChkBox' + index}
                            />
                        }
                        label={item.label}
                    />
                )}
            </React.Fragment>
        )
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { classes, isReadOly, pageAccess, details, states, cities } = this.props;
        const { orgs, users } = details;
        const { name, totalSites, totalBidgs, totalUnits, desc, address, city, state, zipcode, org, user, readOnlyFunders,
            orgDet, isConfidentialFlag, duplicateProjects } = this.state;
        const canEditContact = pageAccess.indexOf('EDIT_OWNER_CONTACT_NAME') >= 0 ? true : false;
        const { userType } = this;
        var othrOrg;
        if (details.readOnlyOrg.length > 0) {
            details.readOnlyOrg.map(item => {
                return item.title = item.label
            })
        }
        if (readOnlyFunders.length > 0) {
            readOnlyFunders.map(item => {
                return item.title = item.label
            })
        }
        if (readOnlyFunders && readOnlyFunders.length > 0) {
            othrOrg = readOnlyFunders.reduce((previousValue, currentValue,index) => {
            if(index===0){
               return currentValue.title
            }else{
              return  previousValue +", "+ currentValue.title
            }},"")
        }

        return (
            <div className={`${classes.rootEdit} projTabsGeneralRoot`}>
                <ValidatorForm
                    name="userForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="name"
                                value={name}
                                label="Name"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="totalSites"
                                value={totalSites}
                                label="Total Sites"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="totalBidgs"
                                value={totalBidgs}
                                label="Total Bldgs"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="totalUnits"
                                value={totalUnits}
                                label="Total Units"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="desc"
                                value={desc || ''}
                                label="Description"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextBox
                                name="address"
                                value={(address || '').trimStart()} 
                                label="Address"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} >
                            <AutoComplete
                                placeholder='City'
                                className="autocomplete-fixed"
                                suggestions={cities}
                                handleAutoCompolete={this._handleAutoComplete}
                                selectedValue={{ value: city, id: city }}
                                disabled={isReadOly}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                suggestions={states}
                                className="autocomplete-fixed"
                                handleAutoCompolete={this.handleAutoCompleteState}
                                selectedValue={{ value: state, id: state }}
                                disabled={isReadOly}
                                placeholder="State"
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="zipcode"
                                value={(zipcode || '').trimStart()}
                                label="Zip Code"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                name="org"
                                className="autocomplete-fixed fixOverlapContractor"
                                suggestions={orgs}
                                handleAutoCompolete={this.handleAutoCompleteOrg}
                                selectedValue={org}
                                disabled={isReadOly}
                                placeholder="Contractor/Owner"
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="org.address.line"
                                value={org.address.line || ''}
                                label="Address"
                                className={`${classes.textField} textFieldBottom`}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='City'
                                className="autocomplete-fixed"
                                suggestions={cities}
                                handleAutoCompolete={this._handleAutoComplete}
                                selectedValue={{ value: org.address.city, id: org.address.city }}
                                disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='State'
                                className="autocomplete-fixed"
                                suggestions={states}
                                selectedValue={{ value: org.address.state, id: org.address.state }}
                                disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="org.address.postalCode"
                                value={org.address.postalCode || ''}
                                label="Zip Code"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                suggestions={users}
                                name="autocompleteuser"
                                className="autocomplete-fixed"
                                handleAutoCompolete={this.handleAutoCompleteUser}
                                selectedValue={user}
                                disabled={canEditContact ? false : isReadOly}
                                placeholder="Owner Contact Name"
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="user.address.line"
                                value={user.address.line || ''}
                                label="Address"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='City'
                                className="autocomplete-fixed"
                                suggestions={cities}
                                handleAutoCompolete={this._handleAutoComplete}
                                selectedValue={{ value: user.address.city, id: user.address.city }}
                                disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='State'
                                className="autocomplete-fixed"
                                suggestions={states}
                                selectedValue={{ value: user.address.state, id: user.address.state }}
                                disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="user.address.postalCode"
                                value={user.address.postalCode || ''}
                                label="Zip Code"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="user.address.phone"
                                value={user.phone || ''}
                                label="Phone Number"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="user.address.email"
                                value={user.email || ''}
                                label="Email Id"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        {["superFunder", "superFunderWithHO", "funderWithOA", "funderWithOAandBoth"].indexOf(orgDet.role) >= 0 ?
                            <Grid item xs={12} sm={6} className={'readonlyorgAutocomplete'} >
                                <div class="containers" >
                                    <span class={isReadOly === true && readOnlyFunders.length > 0 ? "certtooltiptextHover" : "tooltiptextNone"}>{othrOrg}</span>
                                    <AutoCompleteMultiMUI
                                        placeholder='Other Associated Organizations'
                                        className="autocomplete-fixed"
                                        suggestions={details.readOnlyOrg}
                                        handleAutoCompolete={this._handleROFAutoComplete}
                                        selectedValue={readOnlyFunders}
                                        disabled={isReadOly}
                                        validators={[]}
                                        errorMessages={[]}
                                    />
                                </div>
                            </Grid> : ""}
                        {userType === "Read Only Organization" || userType === "Funder" ?
                            <Grid className={'confidentialProject'}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={isReadOly}
                                            checked={isConfidentialFlag}
                                            onChange={(evt) => { this.handleChange('isConfidentialFlag', evt.target.checked ? true : false) }}
                                            value={2}
                                            color="primary"
                                        />
                                    }
                                    label="Confidential Location?"
                                />
                            </Grid> : ""
                        }
                        {
                            pageAccess.indexOf('SAVE') > -1 || canEditContact === true ? (
                                <SaveCancel
                                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }}
                                />
                            ) : null
                        }
                    </Grid>


                </ValidatorForm>
                <DeleteDialog
                    open={this.state.confirmPopup}
                    content={"A Project with this name already exists. Do you still wish to continue?"}
                    handleDeleteAction={() => {
                        this._saveForm();
                        this.setState({ confirmPopup: false })
                    }}
                    id={this.state.deleteItemId}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ confirmPopup: false })
                    }}
                />
                {
                    <AlertDialog
                        open={this.state.claimTrue}
                        onClose={() => {
                            this.setState({ claimTrue: false })
                        }}
                        onSave={() => {
                            this.onPopupOk();
                        }}
                        saveVisible={duplicateProjects.length > 1 ? false : true}
                        saveText={'Claim'}
                        cancelText={'Cancel'}
                    >
                        {
                            duplicateProjects.length > 1 ?
                                <>
                                    <div className="orOption">A Project with this name already exist. Do you still wish to continue?</div>
                                    <br />
                                    <TableComponent
                                        header={Object.keys(duplicateProjects[0]).filter(elem => elem !== '_id')}
                                        field={Object.keys(duplicateProjects[0]).filter(elem => elem !== '_id')}
                                        actions={['CLAIM']}
                                        data={duplicateProjects}
                                        highlightField={['_id']}
                                        searchVisible={false}
                                        paginationVisible={false}
                                        handleClaimClick={(id, item) => {
                                            this.setState({ claimTrue: false })
                                            history.replace(`/projects/${id}/funders/new`);
                                        }}
                                        removedSortingRow={['Project Key', 'Project Name', 'Line', 'City', 'State', 'Zipcode']}
                                    />

                                </>
                                :
                                <div className="orOption">A Project with this name already exist. Do you still wish to continue?</div>
                        }
                    </AlertDialog>
                }
            </div >
        );
    }
}

/**
 *
 */
General.defaultProps = {
    isReadOly: false,
    projectId: null,
    copyId: null,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}


/**
 * Bind Component Property Types
 */
General.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

/**
 * Maps state from store to props
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
    return {
        projects: state.projects,
        details: state.projects.getOne,
        states: state.configuration.configurations[configurationConstants.state] || [],
        cities: state.configuration.configurations[configurationConstants.cities] || []
    }
};

/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        isProjectNameExists: (data, callBack) => dispatch(projectsActions.isProjectNameExists(data, callBack)),
        getById: (id) => dispatch(projectsActions.getById(id)),
        getAllOrgs: (text, page) => dispatch(projectsActions.getAllOrg(text, page)),
        getAllUsersInOrg: (orgId) => dispatch(projectsActions.getUsersInOrg(orgId)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getReadOnlyOrg: () => dispatch(projectsActions.getReadOnlyOrg()),
        findFunderHistory: (projectId, org_id, callBack) => dispatch(projectsActions.findFunderHistory(projectId, org_id, callBack))

    }
};


/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(General));
