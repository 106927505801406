import { monitoringCycleConstants } from "../constants/monitoringCycle";

const initialState = {
    data : [],
    logList: [],
    tempLogList: [],
    isLoading: false,
    dropDownFunderList: [],
    error: '',
    duplicateRecords : [],
    duplicateInspectingRecords: [],
    isDuplicateError: false,
    isDuplicateIFError: false,
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['report_name'],
            searchCols: []
        }
    },
    downloadStatus: {
        isLoading: false,
        error: ''
    }
}

export function monitoringCycle (state = initialState, action) {
    switch (action.type) {
        case monitoringCycleConstants.CREATE_MONITORING_CYCLE_REQUEST :{
            return {
                ...state,
                isLoading:true
            }
        }
        case monitoringCycleConstants.CREATE_MONITORING_CYCLE_SUCCESS :{
            return{
                ...state,
                isLoading:false
            }
        }
        case monitoringCycleConstants.CREATE_MONITORING_CYCLE_FAILURE :{
            return{
                ...state,
                error: action.error,
                isLoading:false
            }
        }
        case monitoringCycleConstants.GETMONITORINGCYCLELOGS_REQUEST : 
                return {
                    ...state,
                    isLoading: true
                }
        case monitoringCycleConstants.GETMONITORINGCYCLELOGS_SUCCESS : 
                return {
                    ...state,
                    data: action.monitoringCycle,
                    page: action.monitoringCycle.page,
                    logList: action.monitoringCycle.data,
                    tempLogList: action.monitoringCycle.data,
                    isLoading: false
                }
        case monitoringCycleConstants.GETMONITORINGCYCLELOGS_FAILURE :
                return {
                    ...state,
                    error: action.error,
                    isLoading: false,
                }
        case monitoringCycleConstants.GET_FUNDER_LIST_REQUEST: 
                return {
                    ...state,
                    isLoading: true
                }
        case monitoringCycleConstants.GET_FUNDER_LIST_SUCCESS: 
                return {
                    ...state,
                    dropDownFunderList: action.funderList.data,
                    isLoading: false
                }
        case monitoringCycleConstants.GET_FUNDER_LIST_ERROR: 
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        case monitoringCycleConstants.DELETE_REQUEST :
            return {
                ...state,
                isLoading: true
            }
        case monitoringCycleConstants.DELETE_SUCCESS : 
            return {
                ...state,
                isLoading: false
            }
        case monitoringCycleConstants.DELETE_FAILURE : 
            return {
                ...state.page,
                error: action.error,
                isLoading: false
            }
        case monitoringCycleConstants.CHANGE_MONITORING_CYCLE_LOGS : 
                return {
                    ...state,
                    logList: action.updatedData
                }
        case monitoringCycleConstants.CHANGE_TEMP_MONITORING_CYCLE_LOGS: 
                return {
                    ...state,
                    tempLogList:  action.updatedData
                }
        case monitoringCycleConstants.CLEAR_DUPLICATE_FLAG:
                return {
                    ...state,
                    duplicateRecords : [],
                    duplicateInspectingRecords: [],
                    isDuplicateError: false,
                    isDuplicateIFError: false,
                }
        case monitoringCycleConstants.CHECK_DUPLICATE_REQUEST : 
                return {
                    ...state,
                    isLoading: true
                }
        case monitoringCycleConstants.CHECK_DUPLICATE_SUCCESS : 
                return {
                    ...state,
                    isLoading: false,
                    duplicateRecords: action.duplicateRec?.data?.duplicateEntry,
                    isDuplicateError: action.duplicateRec?.data?.isDuplicateEntry,
                    duplicateInspectingRecords: action.duplicateRec?.data?.duplicateInspectingFunder,
                    isDuplicateIFError: action.duplicateRec?.data?.isDuplicateInspectingFunder,
                }
        case monitoringCycleConstants.CHECK_DUPLICATE_FAILURE : 
                return {
                    ...state,
                    error: action.error,
                    isLoading: false,
                }
        case monitoringCycleConstants.DOWNLOAD_REQUEST : 
            return {
                ...state,
                isLoading: true,
                downloadStatus: {
                    isLoading: true
                }
            };
        
        case monitoringCycleConstants.DOWNLOAD_SUCCESS : 
            return {
                ...state,
                isLoading: false,
                downloadStatus: {
                    isLoading: false
                }
            };
        case monitoringCycleConstants.DOWNLOAD_FAILURE : 
                return {
                    ...state,
                    error: action.error,
                    isLoading: false,
                    downloadStatus: {
                        isLoading: false
                    }
                };
        case monitoringCycleConstants.CHANGE_PAGE_DETAILS: 
                return {
                    ...state,
                    page: {
                        ...state.page,
                        count:action.pageDetails.count,
                        limit:action.pageDetails.limit
                    }
                }

        default:
            return state
    }
}