import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';	

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
// Import Style
import tableStyle from '../../assets/jss/components/table';
import HomeIcon from '../../assets/img/h_icon.svg';
import NHTFIcon from '../../assets/img/n_icon.svg';
import PSHIcon from '../../assets/img/p_icon.svg';

// Import Component
import SearchInput from '../../components/SearchInput/SearchInput';
// import AutoComplete from '../../components/AutoComplete/AutoComplete';
import Pagination from "../Pagination/Pagination";
import PrevNext from "../Pagination/PrevNext";
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import ToolTip from '../../components/ToolTip/ToolTip';
import { CopyIcon, DeleteIcon, DownloadIcon, RetriveIcon, SendIcon, SheildIcon, SvgLoadIcon, TableIcon, TransferIcon, SubmitIcon, UnSubmitIcon,PencilsquareIcon,MailTemplateIcon ,DownloadIconInXMLNotification, DownloadIconInXMLNotificationTablelist, HelpIcon } from '../../components/SvgIcons/SvgIcons';

// Helpers
import { constantColors,userStatusConstants } from '../../helpers/appConstants';
import { userRoleConstants } from '../../helpers';
import { history } from '../../helpers';
import PageLoader from '../PageLoader/PageLoader';

import FabButton from '@material-ui/core/Fab';

let DEFAULT_DEL_TITLE = "Are you sure you want to delete this record?";

/**
 *Table component
 *
 * @class TableComponent
 * @extends {Component}
 */
class TableComponent extends Component {

    state = {
        dialogName: '',
        open: false,
        deleteItemId: '',
        title: DEFAULT_DEL_TITLE
    };
    /**
     * Handle Delete On Click Open
     * @param {*} id 
     * @param {*} item 
     */
    handleDeleteClickOpen = (id, item) => {
        let deleteTitle = DEFAULT_DEL_TITLE
        if (this.props.getDeleteTitle) {
            deleteTitle = this.props.getDeleteTitle(item);
        }
        if (item.isLastOA) {
            deleteTitle = <p><b>User: {item.firstName} {item.lastName}</b> is the last Administrator contact for <b>Organization: {item.org_id.name}</b>! Are you sure you want to delete <b>User: {item.firstName} {item.lastName}</b>?</p>;
        }
        this.setState({ dialogName: 'delete', open: true, deleteItemId: id, itemData: item, title: deleteTitle });
    };
    /**
     * Handle Retrive On Click Open
     * @param {*} id 
     * @param {*} item 
     */
    handleRetriveClickOpen = (id, item) => {
        this.setState({ dialogName: 'retrive', open: true, deleteItemId: id, itemData: item, title: "Are you sure you want to retrive this record?" });
    };
    /**
     * Handle Send Action
     * @param {*} id 
     * @param {*} item 
     */
    handleSendAction = (id, item) => {
        this.props.handleSendAction(id, item);
    }
    /**
     * Handle Claim Action
     * @param {*} id 
     * @param {*} item 
     */
    handleClaimClick = (id, item) => {
        this.props.handleClaimClick(id, item);
    }

    /**
     * Handle CheckBox Action
     * @param {*} event
     * @param {*} index 
     * @param {*} item 
     */
    handleCheckBoxAction = (e,index,item) => {
        this.props.handleCheckBoxAction(e.target.checked,index,item)
    }
   
    /**
     * Handle Claim Action
     * @param {*} id 
     * @param {*} item 
     */
     handleXmlEdit = (item) => {
        this.props.handleXmlEdit(item);
    }

    /**
     * Handle Claim Action
     * @param {*} id 
     * @param {*} item 
     */
     handleXmlEmailSend = (item) => {
        this.props.handleXmlEmailSend(item);
    }
    /**
     * Page Navigation Event
     * @param {*} page 
     */
    handleChangePage = (page) => {
        this.props.onChangePageTable(page, this.props.rowsPerPage);
    };
    /**
     * Handle Change Rows Per Page
     * @param {*} event 
     */
    handleChangeRowsPerPage = event => {
        this.props.onChangePageTable(this.props.page, event.target.value);
    };
    /**
     * Handle Sorting
     * @param {*} property 
     * @returns 
     */
    handleSorting = property => event => {
        const orderBy = property;
        let order = 'desc';
        if (this.props.orderBy === orderBy && this.props.order === 'desc') {
            order = 'asc';
        }
        this.props.onSortRequest(orderBy, order);
    };
    /**
     * Request change on input function
     * @param {*} field 
     * @param {*} value 
     */
    onInputChangeRequest = (field, value) => {
        this.props.onInputChangeRequest(field, value);
    };

    onDropDownChangeRequest = (value) =>{
        this.props.onDropDownChangeRequest('userStatus',value.id)
    }
    //  Handle dropdown in organization status
    onDropDownChangeRequestforOrg = (value) =>{
        this.props.onDropDownChangeRequestforOrg('orgStatus',value.id)
    }
    /**
     * Handle Table Delete Event
     * @param {*} id 
     */
    handleTableDeleteAction = (id) => {
        this.props.handleTableDeleteAction(id, this.state.itemData);
        this.setState({ open: false, deleteItemId: '', itemData: null });
    };
    /**
     * Table delete function on close action event
     */
    handleTableDeleteOnCloseAction = () => {
        this.setState({ open: false, deleteItemId: '', itemData: null })
    }
    /**
     * Handle Table Navigation Event with two links
     * @param {*} item 
     * @param {*} field 
     */
    handleNavigateAction = (item, field) => {
        this.props.handleNavigateAction(item._id, field, item);
    }
    /**
     * Table Header
     */
    tableHeaderRow = () => {
        return (
            <React.Fragment>
                <TableRow>
                    {this.props.moreInfoIcons.length > 0 &&
                        <TableCell key={'hrow-moreInfo'} > {''} </TableCell>
                    }
                    {this.props.header.map((item, index) =>
                        <React.Fragment key={'frow-' + index}>
                            {this.props.removedSortingRow.indexOf(item) < 0 &&
                                <TableCell key={'hrow-' + index} sortDirection={this.props.orderBy === this.props.field[index] ? this.props.order : false}>
                                    <TableSortLabel
                                        active={this.props.orderBy === this.props.field[index]}
                                        direction={this.props.order}
                                        onClick={this.handleSorting(this.props.field[index])}>{item}</TableSortLabel>
                                </TableCell>
                            }
                            {this.props.removedSortingRow.indexOf(item) >= 0 &&
                                <TableCell > 
                                    {item} 
                                    {item == 'Status of the Records' && 
                                        <span style={{paddingLeft: '5px'}}>
                                            <ToolTip title={'Records are the information given within open and close tag of Table1_Import_Record in the XML.'} placement="bottom">
                                                <FabButton variant="circular" size="small" color="default" style={{width: '18px',height: '18px', minWidth: '18px',boxShadow: 'none', minHeight: '18px' }}>
                                                    <HelpIcon height='10px' width='10px' />
                                                </FabButton>
                                            </ToolTip>
                                        </span>
                                    }
                                </TableCell>
                            }
                        </React.Fragment>
                    )
                    }
                    {this.props.header.length > 0 &&
                        this.props.actions && (this.props.actions.indexOf('COPY') >= 0 ||
                            this.props.actions.indexOf('EDITBUTTON') >= 0 ||
                            this.props.actions.indexOf('EMAILBUTTON') >= 0 ||
                            this.props.actions.indexOf('DOWNLOADBUTTON') >= 0 ||
                            this.props.actions.indexOf('DELETE') >= 0 ||
                            this.props.actions.indexOf('TRANSFER') >= 0 ||
                            this.props.actions.indexOf('SUBMIT') >= 0 ||
                            this.props.actions.indexOf('UNSUBMIT') >= 0 ||
                            this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                            this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                            this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                            this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                        <TableCell key={'hrow-action'} className={this.props.classes.lastCol} > {'Actions'} </TableCell>
                    }
                </TableRow >
                <TableRow className={'loaderRow'}>
                    <TableCell rowSpan={this.props.header.length + 3} colSpan={this.props.header.length + 3}>
                        {this.props.isLoading === true && this.props.data.length > 0 &&
                            <div className={'linearProgressContainer'}>
                                <LinearProgress color="primary" />
                            </div>
                        }
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    };
    /**
     * Binding Select Field
     * @param {*} item 
     * @param {*} pathArr 
     * @returns 
     */
    bindSelectField = (item, pathArr) => {
        if(_.isEqual(item, this.props.data[this.props.data.length-1])){	
            item.isLastEle=true	
           }else{	
            item.isLastEle=false	
           }
        const { componentMap, fieldComponentOnChange } = this.props;
        let actions = this.props.actions.slice();
        if (componentMap[pathArr]) {
            const FieldComponent = componentMap[pathArr];
            let suggestions = pathArr == 'inspectingFunder' ? { options: item['inspectingFunder'] } : {};
            if (pathArr == 'inspectingFunder' ) {
                return <Autocomplete 
                    renderInput={(params) => <TextField {...params} variant="standard" size="small" sx={{ marginTop: 1.80 }} />}
                    {...suggestions}
                    disableClearable
                    sx={{ width: 150, height: 50 }}
                   />
            }
            return <FieldComponent
                key={item._id}
                config={this.props.config}
                path={pathArr}
                data={item}
                handleChange={fieldComponentOnChange}
                actions={actions}
            />
        } else {
            let arr = pathArr.split('.');
            return arr.reduce((obj, key) => (obj && obj[key] !== 'undefined')
                ? this.getStringLength(obj[key])
                : undefined,
                item
            );
        }
    };
    /**
     * Binding ToolTip Value
     * @param {*} item 
     * @param {*} pathArr 
     * @returns 
     */
    bindToolTipValue = (item, pathArr) => {
        let arr = pathArr.split('.');
        if (pathArr === "submissionAccess") {
            return
        }
        return arr.reduce((obj, key) => (obj && obj[key] !== 'undefined')
            ?
            (key === 'isActive' ? (obj[key] === false ? 'InActive' : 'Active') : obj[key] === false ? 'Read/Write' : (obj[key] === true ? 'Read' : obj[key]))
            : undefined,
            item
        );
    }
    /**
     * Getting String Length
     * @param {*} str 
     * @returns 
     * 
     * truncate if too long and elipsis...
     */
    getStringLength = (str) => {
        if (str && str.length > 75 && typeof (str) === 'string' && this.props.ellipsis && this.props.currentPage==="Table1List") {
            return str.substr(0, 75) + '...';
        }
        else if(str  && typeof (str) === 'string' && this.props.ellipsis&& this.props.currentPage==="Table1List"){
                return str
        }
        else if (str && str.length > 22 && typeof (str) === 'string' && this.props.ellipsis) {
            return str.substr(0, 22) + '...';
        }
        else{
            return str
        }
        return str;
    }
    /**
     * Table Filter
     */
    tableFilterRow = () => {
        const tableRow = this.props.search.map((item, index) => {
            const colName = item ? item : "";
            const sValIndex = this.props.sFields.indexOf(colName);
            if(item==='userStatus' || item === 'orgStatus' || item === 'monitoringCycle'){
            return(
                <TableCell key={index} style={{marginTop:'5px'}}>
                    {
                        <Autocomplete
                        disableClearable
                        disablePortal
                        defaultValue={'Active'}
                        options={userStatusConstants}
                        sx={{ width: 150, height: 50 }}
                        renderInput={(params) => <TextField {...params} variant="standard" size="small" sx={{ marginTop: 1.90 }}  />}
                        onChange={(event, value) =>
                          item === "userStatus"
                            ? this.onDropDownChangeRequest(value)
                            : this.onDropDownChangeRequestforOrg(value)
                        }
                        onInputChange={(event, value) =>
                          item === "userStatus"
                            ? this.onDropDownChangeRequest(value)
                            : this.onDropDownChangeRequestforOrg(value)
                        }
                      />
                    }
                </TableCell>
                )
            }else{
            return (
                <TableCell key={index} className={this.props.classes.searchRow}>
                    {
                        item != null &&
                        <SearchInput
                            placeholder={'Search Here'}
                            header={this.props.header[index]}
                            value={sValIndex >= 0 ? this.props.sValues[sValIndex] : ''}
                            reset={this.props.resetSearchContent}
                            field={this.props.shiftFields ? this.props.search[index] : this.props.field[index]}
                            onInputChangeRequest={this.onInputChangeRequest}
                            onEnter={this.onEnter}
                            isLoading={this.props.isLoading}
                        />
                    }
                </TableCell>
            );
               }
        });
        return (
            <TableRow>
                {this.props.moreInfoIcons.length > 0 &&
                    <TableCell className={this.props.classes.searchRow}> {''} </TableCell>
                }
                {tableRow}
                {this.props.actions &&
                    (this.props.actions.indexOf('COPY') >= 0 ||
                        this.props.actions.indexOf('EDITBUTTON') >= 0 ||
                        this.props.actions.indexOf('EMAILBUTTON') >= 0 ||
                        this.props.actions.indexOf('DOWNLOADBUTTON') >= 0 ||
                        this.props.actions.indexOf('DELETE') >= 0 ||
                        this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                        this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                        this.props.actions.indexOf('TRANSFER') >= 0 ||
                        this.props.actions.indexOf('SUBMIT') >= 0 ||
                        this.props.actions.indexOf('UNSUBMIT') >= 0 ||
                        this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                        this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                    <TableCell className={this.props.classes.searchRow} />
                }
            </TableRow>
        )
    };
    /**
     * Table Row Entry
     */
    tableDataRow = () => {
        return (
            <React.Fragment>
                {this.props.data.map((item, index) =>
                    <TableRow key={index}>
                         {
                            this.props.checkBoxVisible && 
                            <Checkbox 
                               checked={item['isChecked']}
                               onChange={(e) => this.handleCheckBoxAction(e,index,item)}
                            />
                        }
                        {
                            this.tableDataRowValue(item)
                        }
                    </TableRow>
                )}
            </React.Fragment>
        )
    };
    /**
     * Value entry in table row
     * @param {*} item 
     * @returns 
     * 
     * [1]  -   check the validation color and applying the style
     */
    tableDataRowValue = (item) => {
        const { userAccess,currentPage } = this.props
        // check the color theme
        var y = history.location.pathname.split('/')
        var doesLoan = y.includes('loanProjects')
        var getHO = userAccess === 2 && y.includes("projects") && y.includes("users")
        var getBoth = userAccess === 3 && y.includes('projects') && y.includes("users") && y.includes("homeOwnership")
        return (
            <React.Fragment>
                {this.props.moreInfoIcons.length > 0 &&
                    this.bindMoreInfoIcons(item)
                }
                {this.props.field.map((field, index) => {
                    // [1]
                    let validation_color = this.props.highlightField.indexOf(field) > -1 ? constantColors[item.finalStatus] : '';
                    const boldText = this.props.boldText.indexOf(field) > -1 && item['140Per'] === true ? doesLoan ? `${"loanBoldLink bolderText"}` : 'bolderText' : '';
                    return this.props.links && this.props.links.indexOf(field) >= 0
                        ? <TableCell
                            title={this.bindToolTipValue(item, field)}
                            key={'value-' + index}
                            className={doesLoan || getBoth || getHO || (item.userAccess && item.userAccess === "Home Ownership") || (item.organizationAccess && item.organizationAccess === "Home Ownership") ? ('loanLinkCell ' + validation_color + 'linkCell ' + validation_color) : ('linkCell ' + validation_color)}
                            onClick={() => this.handleNavigateAction(item, field)}
                        >
                            <div> {this.bindSelectField(item, field)} </div>
                        </TableCell>
                        : <TableCell
                            title={field==="contractNumber"?"":this.bindToolTipValue(item, field)}                            style={currentPage==="Table1List"&&field==="filename"?{width:'50%'}:currentPage==="Table1List"?{width:'10%'}:{}}
                            key={'value-' + index}
                            className={field === "fundersName" && doesLoan ? `funderLink ${validation_color} ${boldText}` : `${validation_color} ${boldText}`}
                        >
                            <div> {this.bindSelectField(item, field)} </div>
                        </TableCell>
                }
                )}
                {this.props.actions &&
                    (this.props.actions.indexOf('COPY') >= 0 ||
                        this.props.actions.indexOf('DELETE') >= 0 ||
                        this.props.actions.indexOf('TRANSFER') >= 0 ||
                        this.props.actions.indexOf('SUBMIT') >= 0 ||
                        this.props.actions.indexOf('UNSUBMIT') >= 0 ||
                        this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                        this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                        this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                        this.props.actions.indexOf('CLAIM') >= 0 ||
                        this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                    this.addAction(item)
                }
            </React.Fragment>
        )
    };
    /**
     * Bind More Info Icons
     * @param {*} item 
     * @returns 
     */
    bindMoreInfoIcons = (item) => {
        return (
            <TableCell className={'noPadding'} >
                <div className={'tblMoreInfoIconCel'}>
                    {this.props.moreInfoIcons.indexOf('ROLE') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('HOME') >= 0 && item.isHomeProject !== undefined &&
                                item.isHomeProject === true ?
                                <span className='homeCls visible'>
                                    <img src={HomeIcon} title={item.setAsideType} alt='Home Program' />
                                </span>
                                : <span className='homeCls hidden'>
                                    <img src={HomeIcon} title='Home Program' alt='Home Program' />
                                </span>
                            }
                        </React.Fragment>
                    }
                    {this.props.moreInfoIcons.indexOf('ROLE') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('NHTF') >= 0 && item.isNHTFProject !== undefined &&
                                item.isNHTFProject === true ?
                                <span className='homeCls visible'>
                                    <img src={NHTFIcon} title={item.setAsideTypeNHTF} alt='NHTF Program' />
                                </span>
                                : <span className='homeCls hidden'>
                                    <img src={NHTFIcon} title='NHTF Program' alt='NHTF Program' />
                                </span>
                            }
                        </React.Fragment>
                    }
                    {this.props.moreInfoIcons.indexOf('ROLE') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('PSH') >= 0 && item.isPSHProject !== undefined &&
                                item.isPSHProject === true ?
                                <span className='homeCls visible'>
                                    <img src={PSHIcon} title={item.setAsideTypePSH} alt='PSH Program' />
                                </span>
                                : <span className='homeCls hidden'>
                                    <img src={PSHIcon} title='PSH Program' alt='PSH Program' />
                                </span>
                            }
                        </React.Fragment>
                    }
                    {this.props.moreInfoIcons.indexOf('HOME') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('ACTIVE') >= 0 && item.isActive !== undefined
                                && item.isActive === false ?
                                <span className='activeCls visible'>*</span>
                                : <span className='activeCls hidden'>*</span>
                            }

                            {this.props.moreInfoIcons.indexOf('ROLE') >= 0 && item.role !== undefined &&
                                ((item.role === userRoleConstants.organizationAdmin && item.access === true) && (item.homeOwnershipAccess.role === userRoleConstants.organizationAdmin && item.homeOwnershipAccess.access===true)) ?
                                <span className='roleCls visible' title='Organization Admin'>
                                    <SheildIcon/>
                                    <SheildIcon fill='#2f67cc'/>

                                </span>
                                : 
                                (item.homeOwnershipAccess.role === userRoleConstants.organizationAdmin && item.homeOwnershipAccess.access===true) ?
                                <span className='roleCls visible' title='Organization Admin'>
                                    <SheildIcon fill='#2f67cc' />
                                </span>
                                :
                                (item.role === userRoleConstants.organizationAdmin && item.access===true) ?
                                <span className='roleCls visible' title='Organization Admin'>
                                    <SheildIcon />
                                </span>
                                :
                                <span className='roleCls hidden' title='Organization Admin'>
                                    <SheildIcon />
                                </span>
                            }
                            
                        </React.Fragment>
                    }
                </div>
            </TableCell>
        )
    }
    /**
     * Adding action
     * @param {*} item 
     * @returns 
     * 
     * [1]  -   filter out DELETE & COPY & TRANSFER
     * [2]  -   for table 1
     */
    addAction = (item) => {
        // check the color theme
        var y = history.location.pathname.split('/')
        var doesLoan = y.includes('loanProjects')

        const { setLabel, deleteTooltip, actionClassName } = this.props;
        let actions = this.props.actions.slice();
        if (item.disabled) {
            // [1]
            actions = actions.filter(act => { return act !== 'DELETE' && act !== 'TRANSFER'; });
        }
        if (item.isActive === false || item.isPredefined === true) {
            actions = actions.filter(act => { return act !== 'SEND' && act !== 'DELETE'; });
        }
        // [2]
        if (this.props.actions.indexOf('DELETE') >= 0 && item.allowDelete) {
            actions.push('DELETE');
        } else {
            if (y.includes('organizations')) {
                actions = actions.filter(act => act !== 'DELETE');
            }
        }
        //XML import listing page filters

        if((item.status&&item.status.toLowerCase()==="completed"||item.status&&item.status.toLowerCase()==="processing")&&this.props.actions.indexOf('EDITBUTTON') >= 0&&this.props.actions.indexOf('DELETE') >= 0){
            //actions = actions.filter(act =>{ return act !== 'EDITBUTTON' && act !== 'DELETE'});
            actions = actions.filter(act =>{ return act !== 'EDITBUTTON'});
        }

        if((item.status&&item.status.toLowerCase()==="inprogress")&&this.props.actions.indexOf('EDITBUTTON') >= 0){
            actions = actions.filter(act => act !== 'EDITBUTTON');
        }

        if((item.status&& (item.status.toLowerCase() !=="error"))&&this.props.actions.indexOf('DOWNLOADBUTTON') >= 0){
            actions = actions.filter(act => act !== 'DOWNLOADBUTTON');
        }

        if((item.status&& (item.status.toLowerCase() === "partially completed"))&&this.props.actions.indexOf('DOWNLOADBUTTON') >= 0){
            actions = [...actions, "DOWNLOADBUTTON"];
        }

        //BULK EXPORT DATA listing page filters

        if ((item.status && (item.status.toLowerCase() !== "completed" || item.isExpired)) && this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0){
            actions = actions.filter(act => act !== 'REPORT_DOWNLOAD')
        }
        
        return (
            <TableCell key={'actions'} style={this.props.currentPage==="Table1List"?{width:"30%"}:{}} className={this.props.classes.lastCol}>
                <div className={actionClassName}>
                {actions.indexOf('DOWNLOADBUTTON') >= 0 &&
                    <ToolTip title="Download XML Details" placement="bottom">
                         <IconButton className="delete_icon" color="primary" aria-label="Retrieve" onClick={() => {
                            this.props.handleDownloadhtmAction({fileName:item.htmfileName})}}>
                            <DownloadIconInXMLNotificationTablelist/>
                         </IconButton>
                    </ToolTip>
                }

                {actions.indexOf('EDITBUTTON') >= 0 &&
                    <ToolTip title="Edit" placement="bottom">
                        <IconButton className="delete_icon" color="primary" aria-label="Retrieve" onClick={() => this.handleXmlEdit(item)}>
                             <PencilsquareIcon />
                        </IconButton>
                        
                    </ToolTip>
                    }
                       {actions.indexOf('EMAILBUTTON') >= 0 &&
                    <ToolTip title="Send Email" placement="bottom">
                        <IconButton className="delete_icon" color="primary" aria-label="Retrieve" onClick={() => this.handleXmlEmailSend(item)}>
                          <MailTemplateIcon/>
                        </IconButton>
                        
                    </ToolTip>
                    }

                    {actions.indexOf('COPY') >= 0 &&
                        <ToolTip title="Copy" placement="bottom">
                            <IconButton className="copy_icon" color="primary" aria-label="Copy" onClick={() => this.props.handleTableCopyAction(item._id)}>
                                <CopyIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('TRANSFER') >= 0 &&
                        <ToolTip title="Transfer" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Download" onClick={() => this.props.handleTransferAction(item)}>
                                <TransferIcon />
                            </IconButton>
                        </ToolTip>
                    }

                    {actions.indexOf('SUBMIT') >= 0 &&
                        <ToolTip title="Submit" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Submit" onClick={() => this.props.handleSubmitAction(item)}>
                                <SubmitIcon />
                            </IconButton>
                        </ToolTip>
                    }

                    {actions.indexOf('UNSUBMIT') >= 0 &&
                        <ToolTip title="UnSubmit" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Unsubmit" onClick={() => this.props.handleUnSubmitAction(item)}>
                                <UnSubmitIcon />
                            </IconButton>
                        </ToolTip>
                    }

                    {actions.indexOf('VIEW_REPORT') >= 0 &&
                        <ToolTip title={setLabel ? setLabel(item, "View Report") : "View Report"} placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="View" onClick={() => this.props.handleViewReportAction(item)}>
                                <TableIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('REPORT_DOWNLOAD') >= 0 &&
                        <ToolTip title="Download Report" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Download" onClick={() => this.props.handleDownloadAction(item)}>
                                <DownloadIcon fill={doesLoan ? '#2F67CC' : '#36aebe'} />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('SEND') >= 0 &&
                        <ToolTip title="Send" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Send" onClick={() => this.handleSendAction(item._id, item)}>
                                <SendIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    
                    {actions.indexOf('DELETE') >= 0 && actions.indexOf('ARCHUSR') <= 0 &&
                        <ToolTip title={deleteTooltip} placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Delete" onClick={() => this.handleDeleteClickOpen(item._id, item)}>
                                <DeleteIcon />
                            </IconButton>
                        </ToolTip>
                    }

                    {actions.indexOf('RETRIEVE') >= 0 &&
                        <ToolTip title='Retrieve' placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Retrieve" onClick={() => this.handleRetriveClickOpen(item._id, item)}>
                                <RetriveIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    
                    {actions.indexOf('CLAIM') >= 0 &&
                        <IconButton className="delete_icon" color="primary" aria-label="Retrieve" onClick={() => this.handleClaimClick(item._id, item)}>
                            Claim
                        </IconButton>
                    }
                     
                </div>
            </TableCell>
        )
    };

    /**
     * Bind HTML to DOM
     */
    render() {
        const { classes, data, rowsPerPage, page, count, paginationVisible, noDataErrorMessage,
            isLoading, searchVisible, header, showLoading, prevNext, showMsgInside, style, userAccess, currentPage, checkBoxVisible  } = this.props;
        const itemData = this.state.itemData || {};
        return (
            <div style={{ padding: '0px 10px', height: "100%" }}>
                <TableContainer className={currentPage==="contractList"?"contractListTabRoot":classes.container} style={style}>
                    <Table className={classes.table}>
                        <TableHead>
                            {header.length > 0 &&
                                this.tableHeaderRow()
                            }
                        </TableHead>
                        <TableBody>
                            {searchVisible && header.length > 0 &&
                                this.tableFilterRow()
                            }

                            {data.length > 0 &&
                                this.tableDataRow()
                            }
                        </TableBody>
                    </Table>
                    {isLoading === false && data.length <= 0 &&
                        <div className={showMsgInside === true ? 'nocontent-inside' : 'nocontent'}>
                            {noDataErrorMessage}
                        </div>
                    }
                </TableContainer>
                {header.length <= 0 && data.length <= 0 && isLoading === true && showLoading === true &&
                    <div className={'loader'}>
                        <div className="svgloader">
                            <SvgLoadIcon> </SvgLoadIcon>
                        </div>
                    </div>
                }

                <div>
                    {data.length > 0 && paginationVisible &&
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={rowsPerPage}
                            totalItemsCount={count}
                            pageRangeDisplayed={5}
                            onChange={this.handleChangePage}
                            isLoading={false}
                            userAccess={userAccess}
                            disablePagination={this.props.disablePagination}
                        />}
                    {
                        data.length > 0 && prevNext && (
                            <PrevNext
                                onClick={(isNext) => {
                                    if (isNext) {
                                        this.props.onChangePageTable(page + 1, rowsPerPage);
                                    } else {
                                        if (page > 0) {
                                            this.props.onChangePageTable(page - 1, rowsPerPage);
                                        }
                                    }
                                }}
                                isLoading={isLoading}
                            />
                        )
                    }
                    <DeleteDialog
                        open={this.state.open}
                        content={this.state.title}
                        // content={this.state.title}
                        handleDeleteAction={this.handleTableDeleteAction}
                        id={this.state.deleteItemId}
                        handleDeleteOnCloseAction={this.handleTableDeleteOnCloseAction} />
                </div>
                <PageLoader show={this.props.isCheckBoxLoading}/>
            </div>
        );
    }
}
//prop types
TableComponent.propTypes = {
    data: PropTypes.any.isRequired,
    header: PropTypes.any.isRequired,
    field: PropTypes.any.isRequired,
    search: PropTypes.any.isRequired,
    actions: PropTypes.any,
    links: PropTypes.any,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    onChangePageTable: PropTypes.func.isRequired,
    onSortRequest: PropTypes.func.isRequired,
    onInputChangeRequest: PropTypes.func,
    resetSearchContent: PropTypes.bool.isRequired,
    paginationVisible: PropTypes.bool.isRequired,
    handleTableDeleteAction: PropTypes.func,
    handleTableSendAction: PropTypes.func,
    handleNavigateAction: PropTypes.func,
    userOrgId: PropTypes.any,
    userRole: PropTypes.any,
    highlighColor: PropTypes.any,
    highlightField: PropTypes.any,
    noDataErrorMessage: PropTypes.string.isRequired,
    currentPage: PropTypes.string,
    removedSortingRow: PropTypes.array,
    isLoading: PropTypes.bool,
    searchVisible: PropTypes.bool,
    showLoading: PropTypes.bool,
    moreInfoIcons: PropTypes.array,
    handleTransferAction: PropTypes.func,
    handleSubmitAction: PropTypes.func,
    handleUnSubmitAction: PropTypes.func,
    handleDownloadAction: PropTypes.func,
    handleDownloadhtmAction: PropTypes.func,
    boldText: PropTypes.array,
    showMsgInside: PropTypes.bool,
    userAccess: PropTypes.any,
    disablePagination:PropTypes.bool,
    checkBoxVisible: PropTypes.bool,
    shiftFields: PropTypes.bool,
    isCheckBoxLoading: PropTypes.bool
};
//default types
TableComponent.defaultProps = {
    data: [],
    header: [],
    field: [],
    search: [],
    page: 0,
    showLoading: true,
    rowsPerPage: 0,
    count: 0,
    orderBy: '',
    order: 'asc',
    classes: {},
    sFields: [],
    sValues: [],
    onChangePageTable: () => { },
    onSortRequest: () => { },
    onInputChangeRequest: () => { },
    onDropDownChangeRequest: () => { },
    onDropDownChangeRequestforOrg: () => { },
    resetSearchContent: false,
    paginationVisible: false,
    handleTransferAction: () => { },
    handleSubmitAction: () => { },
    handleUnSubmitAction: () => { },
    handleTableDeleteAction: () => { },
    handleTableSendAction: () => { },
    handleNavigateAction: () => { },
    componentMap: {},
    userOrgId: '',
    userRole: '',
    actions: [],
    handleTableCopyAction: () => { },
    highlightField: [],
    noDataErrorMessage: '',
    currentPage: '',
    removedSortingRow: [],
    isLoading: false,
    searchVisible: true,
    config: {},
    handleViewReportAction: () => { },
    handleDownloadhtmAction:()=>{},
    handleDownloadAction: () => { },
    handleSendAction: () => { },
    handleClaimClick: () => { },
    handleXmlEdit: () => { },
    handleXmlEmailSend: () => { },
    moreInfoIcons: [],
    style: {},
    innerStyle: {},
    boldText: [],
    prevNext: false,
    showMsgInside: false,
    ellipsis: true,
    getDeleteTitle: null,
    getSendTitle: null,
    setLabel: null,
    deleteTooltip: 'Delete',
    actionClassName: 'actions-center',
    userAccess: "",
    disablePagination:false,
    handleCheckBoxAction: () => {},
    checkBoxVisible: false,
    shiftFields: false,
    isCheckBoxLoading: false
    
}
//export component
export default withStyles(tableStyle)(TableComponent);
