import { monitoringCycleConstants } from "../constants/monitoringCycle";

const initialState = {
    data : [],
    logList: [],
    checkedData: [],
    isLoading: false,
    error: '',
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['report_name'],
            searchCols: []
        }
    },
    downloadStatus: {
        isLoading: false,
        error: ''
    }
}

export function allSiteLogs (state = initialState, action) {
    switch (action.type) {
        case monitoringCycleConstants.GET_ALL_SITE_LOGS_REQUEST : 
                return {
                    ...state,
                    isLoading: true
                }
        case monitoringCycleConstants.GET_ALL_SITE_LOGS_SUCCESS: 
                return {
                    ...state,
                    data: action.siteLogs,
                    page: action.siteLogs.page,
                    logList: action.siteLogs.data,
                    isLoading: false
                }
        case monitoringCycleConstants.GETMONITORINGCYCLELOGS_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    isLoading: false,
                }
        case monitoringCycleConstants.CHANGE_CHECKED_STATE:
                return {
                    ...state,
                    checkedData: action.updatedData
                }
        case monitoringCycleConstants.CHANGE_ALL_SITE_LOGS:
                return {
                    ...state,
                    logList: action.updatedData
                }
        default:
            return state
    }
}