import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import PageLoader from '../PageLoader/PageLoader';

// Import Component
import SearchInput from '../../components/SearchInput/SearchInput';
import Pagination from "../Pagination/Pagination";
import PrevNext from "../Pagination/PrevNext";
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import ToolTip from '../../components/ToolTip/ToolTip'
import Tooltip from '@mui/material/Tooltip';

// SVG Icons
import { CopyIcon, DownloadIcon, SvgLoadIcon, TableIcon, TransferIcon,InspectionSaveIcon, InspectionCancelIcon, InspectionDeleteIcon  } from '../../components/SvgIcons/SvgIcons';

// Import Style
import tableStyle from '../../assets/jss/components/table';

// Icons
import HomeIcon from '../../assets/img/h_icon.svg';
import NHFTIcon from '../../assets/img/n_icon.svg';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Helpers
import { constantColors } from '../../helpers/appConstants';
import { history, userRoleConstants } from '../../helpers';


let DEFAULT_DEL_TITLE = "Are you sure you want to delete the site from the inspection list?";

const styles = {
    tableRow: {
      '&:hover': {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      },
    },
  };
  

/**
 *Material Table component
 *
 * @class MaterialTableComponent
 * @extends {Component}
 */
class InspectionTableComponent extends Component {

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            open: false,
            deleteItemId: '',
            deleteTitle: DEFAULT_DEL_TITLE,
            selectedRow: null,
            action: "",
            isRowHovered: false,
            isAtEnd: false,
            filterValues: {
                monitoringCycle: { id: 'All', label: 'All' },
                monitoringType: { id: 0, label: 'All' },
                homeState: { id: 'All', label: 'All' },
                homeCity: { id: 'All', label: 'All' },
                homeCounty: { id: 'All', label: 'All' },
                nhtf: { id: 'All', label: 'All' },
                inspectingFunder: { id: 0, label: 'All', description: 'All' }
            }
        };
        this.observer = null;
    }

    handleScroll = () => {
        const scrollLeft = this.tableRef.current.scrollLeft;
        const scrollWidth = this.tableRef.current.scrollWidth;
        const clientWidth = this.tableRef.current.clientWidth;
        if (scrollLeft + clientWidth >= scrollWidth - 400) {
            this.setState({ isAtEnd: true });
            // console.log("== IS AT END");
           // this.tableRef.current.scrollLeft = scrollWidth - clientWidth;
        } else {
            this.setState({ isAtEnd: false });
        }
     };

    // componentDidMount() {
    //     if (this.tableRef.current) {
    //         this.tableRef.current.addEventListener('scroll', this.handleScroll);
    //     }
    //     // const scrollableElement = this.tableRef.current.querySelector('.list-table');
    //     // scrollableElement.addEventListener('scroll', () => { console.log("== SCROLL EVENT") })
    //     // console.log("== Table ref ==",scrollableElement)
    // }
    /**
     * Handle Delete On Click Open
     * @param {*} id 
     * @param {*} item 
     */
    handleDeleteClickOpen = (id, item) => {
        let deleteTitle = DEFAULT_DEL_TITLE
        this.setState({  action: "DELETE"  })
        if (this.props.getDeleteTitle) {
            deleteTitle = this.props.getDeleteTitle(item);
        }
        if(!this.props.isDeleteRequired){
            this.setState({deleteItemId: id, itemData: item, deleteTitle });
            this.props.handleTableDeleteAction(id, this.state.itemData);
        }else{
        this.setState({ open: true, deleteItemId: id, itemData: item, deleteTitle });
        }
       
    };

    handleClearFilters = () => {
        this.setState({
            filterValues: {
                monitoringCycle: { id: 'All', label: 'All' },
                monitoringType: { id: 0, label: 'All' },
                homeState: { id: 'All', label: 'All' },
                homeCity: { id: 'All', label: 'All' },
                homeCounty: { id: 'All', label: 'All' },
                nhtf: { id: 'All', label: 'All' },
                inspectingFunder: { id: 0, label: 'All', description: 'All' }
            }
        });
    };

    handleCancelClickOpen = (id, item) => {
        let deleteTitle = 'Are you sure you want to cancel without saving the changes?';
        this.setState({ action: "CANCEL" });
        if(!this.props.isDeleteRequired){
            this.setState({deleteItemId: id, itemData: item, deleteTitle });
            this.props.handleTableDeleteAction(id, this.state.itemData);
        }else{
        this.setState({ open: true, deleteItemId: id, itemData: item, deleteTitle });
        }
       
    }
    /**
     * Page Navigation Event
     * @param {*} page 
     */
    handleChangePage = (page) => {
        this.props.onChangePageTable(page, this.props.rowsPerPage);
    };
    /**
     * Handle Change Rows Per Page
     * @param {*} event 
     */
    handleChangeRowsPerPage = event => {
        this.props.onChangePageTable(this.props.page, event.target.value);
    };
    /**
     * Handle Sorting
     * @param {*} property 
     * @returns 
     */
    handleSorting = property => event => {
        const orderBy = property;
        let order = 'desc';
        if (this.props.orderBy === orderBy && this.props.order === 'desc') {
            order = 'asc';
        }
        this.props.onSortRequest(orderBy, order);
    };
    /**
     * Request change on input function
     * @param {*} field 
     * @param {*} value 
     */
    onInputChangeRequest = (field, value) => {
        this.props.onInputChangeRequest(field, value);
    };
    /**
     * Handle Table Delete Event
     * @param {*} id 
     */
    handleTableDeleteAction = (id) => {

        if (this.state.action === "DELETE") {
            this.props.handleTableDeleteAction(id, this.state.itemData);
        } else if(this.state.action === "CANCEL") {
            this.props.handleTableClearAction(id, this.state.itemData);
        }
        
        this.setState({ open: false, deleteItemId: '', itemData: null, selectedRow: null });
        this.setState({action: ''})
    };
    /** Table delete function on close action event */
    handleTableDeleteOnCloseAction = () => {
        this.setState({ open: false, deleteItemId: '', itemData: null })
        this.setState({actiion: ''})
    }
    /**
     * Handle Table Navigation Event with two links
     * @param {*} item 
     * @param {*} field 
     */
    handleNavigateAction = (item, field) => {
        this.props.handleNavigateAction(item._id, field, item);
    }

    handleFilterChange = (item, value) => {
        this.setState(prevState => ({
            filterValues: {
                ...prevState.filterValues,
                [item]: value
            }
        }));
    };

    /**
     * Sticky Table Header
     */
    stickyTableHeaderRow = () => {
        return (
            <React.Fragment>
                {
                    this.props.multiHeader &&
                    <TableRow>
                        <TableCell colspan="3"></TableCell>
                    </TableRow>
                }
                <TableRow>
                    {this.props.moreInfoIcons.length > 0 &&
                        <TableCell key={'hrow-moreInfo'} > {''} </TableCell>
                    }
                    {this.props.header.map((item, index) => {
                        return (
                            index < this.props.stickyHeaders.length && <React.Fragment key={'frow-' + index}>
                                {this.props.removedSortingRow.indexOf(item) < 0 &&
                                    <TableCell key={'hrow-' + index} sortDirection={this.props.orderBy === this.props.field[index] ? this.props.order : false}>
                                        {'Actions'}
                                    </TableCell>
                                }
                                {this.props.removedSortingRow.indexOf(item) >= 0 &&
                                    <TableCell > {item} </TableCell>
                                }
                            </React.Fragment>
                        )
                    })
                    }
                </TableRow >
                <TableRow className={'loaderRow'}>
                    <TableCell rowSpan={this.props.header.length + 3} colSpan={this.props.header.length + 3}>
                        {this.props.isLoading === true && this.props.data && this.props.data.length > 0 &&
                            <div className={'linearProgressContainer'}>
                                <LinearProgress color="primary" />
                            </div>
                        }
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    };
    /**
     * Scroll Table Header
     */
    tableHeaderRow = () => {
        return (
            <React.Fragment>
                {
                    this.props.primaryHeader.length > 0 &&
                    <TableRow>
                        {
                            this.props.primaryHeader.map(header => (
                                <TableCell colspan={this.props.staticSpan ? this.props.staticSpan : this.props.header.length / this.props.primaryHeader.length}>{header}</TableCell>
                            ))
                        }
                        {
                            this.props.actions.length > 0 &&
                            <TableCell key={'hrow-moreInfo'} > {''} </TableCell>
                        }
                    </TableRow>
                }
                <TableRow>
                    {this.props.moreInfoIcons.length > 0 &&
                        <TableCell key={'hrow-moreInfo'} > {''} </TableCell>
                    }
                    {this.props.header.map((item, index) => {
                        return (
                            // index > this.props.stickyHeaders.length - 1 && 
                            <React.Fragment key={'frow-' + index}>
                                {this.props.removedSortingRow.indexOf(item) < 0 &&
                                    <TableCell   key={'hrow-' + index} sortDirection={this.props.orderBy === this.props.field[index] ? this.props.order : false}>
                                        <TableSortLabel
                                            className={this.props.isError ? this.props.classes.actionItems : ''}
                                            active={this.props.orderBy === this.props.field[index]}
                                            direction={this.props.order}
                                            onClick={this.handleSorting(this.props.field[index])}>{item}</TableSortLabel>
                                    </TableCell>
                                }

                                {this.props.removedSortingRow.indexOf(item) >= 0 &&
                                    <TableCell > {item} </TableCell>
                                }
                            </React.Fragment>
                        )
                    })
                    }
                    {this.props.header.length > 0 &&
                        this.props.actions && (this.props.actions.indexOf('COPY') >= 0 ||
                            this.props.actions.indexOf('EDITBUTTON') >= 0 ||
                            this.props.actions.indexOf('EMAILBUTTON') >= 0 ||
                            this.props.actions.indexOf('DOWNLOADBUTTON') >= 0 ||
                            this.props.actions.indexOf('DELETE') >= 0 ||
                            this.props.actions.indexOf('TRANSFER') >= 0 ||
                            this.props.actions.indexOf('SUBMIT') >= 0 ||
                            this.props.actions.indexOf('UNSUBMIT') >= 0 ||
                            this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                            this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                            this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                            this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                        <TableCell key={'hrow-action'} className={`${this.props.classes.lastCol}`} > {'Actions'} </TableCell>
                    }
                </TableRow >
                <TableRow className={'loaderRow'}>
                    <TableCell rowSpan={this.props.header.length + 3} colSpan={this.props.header.length + 3}>
                        {this.props.isLoading === true && this.props.data && this.props.data.length > 0 &&
                            <div className={'linearProgressContainer'}>
                                <LinearProgress color="primary" />
                            </div>
                        }
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    };
    /**
     * Binding Select Field
     * @param {*} item 
     * @param {*} pathArr 
     * @param {*} rowIndex 
     * @returns 
     */
    bindSelectField = (item, pathArr, rowIndex) => {
        const { componentMap, fieldComponentOnChange, value } = this.props;
        let actions = this.props.actions.slice();
        if (componentMap[pathArr]) {
            const FieldComponent = componentMap[pathArr];
            let suggestions = pathArr == 'inspectingFunder' ? { options: item['inspectingFunder'] } : {};
            return <FieldComponent
                key={item._id}
                rowIndex={rowIndex}
                config={this.props.config}
                path={pathArr}
                suggestions={item['inspectingFunder']}
                name={pathArr}
                data={item}
                isReadOnly={rowIndex != this.state.selectedRow}
                handleChange={(e,newValue) => fieldComponentOnChange(e,newValue, pathArr, rowIndex, (rowIndex) => { this.setState({selectedRow: rowIndex}) })}
                actions={actions}
            />
        } else {
            let arr = pathArr.split('.');
            return arr.reduce((obj, key) => (obj && obj[key] !== 'undefined')
                ? this.getStringLength(obj[key])
                : undefined,
                item
            );
        }
    };
    /**
     * Binding ToolTip Value
     * @param {*} item 
     * @param {*} pathArr 
     * @returns 
     */
    bindToolTipValue = (item, pathArr) => {
        let arr = pathArr.split('.');
        return arr.reduce((obj, key) => (obj && obj[key] !== null && obj[key] !== 'undefined')
            ?
            ( key == 'monitoringType' ? obj[key]["label"] || '' :  key == 'inspectingFunder' ? obj?.inspectingFunderValue?.label || '' : obj[key])
            : undefined,
            item
        );
    }
    /**
     * Getting String Length
     * @param {*} str 
     * @returns 
     * 
     * truncate if too long and elipsis...
     */
    getStringLength = (str) => {
        if (str && str.length > 22 && typeof (str) === 'string' && this.props.ellipsis) {
            return str.substr(0, 22) + '...';
        }
        return str;
    }
    /**
     * Sticky Table Filter
     */
    stickyTableFilterRow = () => {
        const tableRow = this.props.search.map((item, index) => {
            const colName = item ? item : "";
            const sValIndex = this.props.sFields.indexOf(colName);
            return (
                index < this.props.stickyHeaders.length && <TableCell style={{visibility: 'hidden'}} key={index} className={this.props.classes.searchRow}>
                    {
                        item != null &&
                        <SearchInput
                            placeholder={'Search Here'}
                            header={this.props.header[index]}
                            value={sValIndex >= 0 ? this.props.sValues[sValIndex] : ''}
                            reset={this.props.resetSearchContent}
                            field={this.props.field[index]}
                            onInputChangeRequest={this.onInputChangeRequest}
                            onEnter={this.onEnter}
                            isLoading={this.props.isLoading}
                            isHidden={true}
                        />
                    }
                </TableCell>
            );
        });
        return (
            <TableRow>
                {this.props.moreInfoIcons.length > 0 &&
                    <TableCell className={this.props.classes.searchRow}> {''} </TableCell>
                }

                {tableRow}

                {(this.props.actions ) &&
                    (this.props.actions.indexOf('COPY') >= 0 ||
                        this.props.actions.indexOf('DELETE') >= 0 ||
                        this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                        this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                        this.props.actions.indexOf('TRANSFER') >= 0 ||
                        this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                        this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                    <TableCell className={this.props.classes.searchRow} />
                }
            </TableRow>
        )
    };

    handleRowClick = (rowIndex) => {
        this.setState({ selectedRow: rowIndex});
    };

    /**
     * scroll Table Filter
     */
    tableFilterRow = () => {
        const tableRow = this.props.search.map((item, index) => {
            const colName = item ? item : "";
            const sValIndex = this.props.sFields.indexOf(colName);

            const getSuggestions = (item) => {
                switch (item) {
                    case 'monitoringCycle':
                        return ['All', 'A', 'B', 'C'];
                    case 'monitoringType':
                        return [
                            { id: 0, label: 'All' },
                            { id: 1, label: 'In cycle' },
                            { id: 2, label: 'Placed in Service' },
                            { id: 3, label: 'Out of cycle' },
                            { id: 4, label: 'Risk' }
                        ];
                    case 'inspectingFunder':
                        return [{ id: 0, label: 'All', description: 'All' }, ...this.props.funderList];
                    default:
                        return [
                            { id: 'All', label: 'All' },
                            { id: 'Yes', label: 'Yes' },
                            { id: 'No', label: 'Blank (No)' }
                        ];
                }
            };


            if (item ==='monitoringCycle' || item === 'monitoringType' || item === 'homeState' || item === 'homeCity' || item === 'homeCounty' || item === 'nhtf' || item == 'inspectingFunder'){

                return(
                    <TableCell key={index} className={(this.props.isError || this.state.selectedRow != null) ? this.props.classes.filterRow : ''} style={{marginTop:'5px'}}>
                        {
                            <Autocomplete
                            disableClearable
                            disablePortal
                            defaultValue={'All'}
                            value={this.state.filterValues[item]}
                            options={getSuggestions(item)}
                            renderOption={(props, option) => (
                                item == 'inspectingFunder' ? (
                                  <Tooltip title={option.description} arrow>
                                    <li {...props}>{option.label == '' ? option.description: option.label}</li>
                                  </Tooltip>
                                ) : item == 'monitoringCycle' ? (
                                    <li {...props}>{option}</li>
                                ) :(
                                  <li {...props}>{option.label}</li>
                                )
                              )}
                            sx={{ width: 150, height: 50 }}
                            renderInput={(params) => <TextField {...params} variant="standard" size="small" sx={{ marginTop: 1.90 }}  />}
                            onChange={(event, value) => {
                                (item == 'monitoringType' || item === 'homeState' || item === 'homeCity' || item === 'homeCounty' || item === 'nhtf' || item === 'inspectingFunder') ?
                                this.props.onDropDownChangeRequest(item,value['id']) :
                                this.props.onDropDownChangeRequest(item,value)
                                this.handleFilterChange(item, value)
                            }}
                            disabled={this.props.isError || this.state.selectedRow != null}
                          />
                        }
                    </TableCell>
                )
            }

          
            return (
                <TableCell key={index} className={`${this.props.classes.searchRow} ${this.props.isError ? this.props.classes.filterRow : ''}`}>
                    {
                        item != null &&
                        <SearchInput
                            placeholder={'Search Here'}
                            header={this.props.header[index]}
                            value={sValIndex >= 0 ? this.props.sValues[sValIndex] : ''}
                            reset={this.props.resetSearchContent}
                            field={this.props.field[index]}
                            onInputChangeRequest={this.onInputChangeRequest}
                            onEnter={this.onEnter}
                            isReadOnly={this.props.isError || this.state.selectedRow != null}
                        />
                    }
                </TableCell>
            );
        });
        return (
            <TableRow onClick={this.props.handleFilterRowClick}>
                {this.props.moreInfoIcons.length > 0 &&
                    <TableCell className={this.props.classes.searchRow}> {''} </TableCell>
                }

                {tableRow}

                {this.props.actions &&
                    (this.props.actions.indexOf('COPY') >= 0 ||
                        this.props.actions.indexOf('DELETE') >= 0 ||
                        this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                        this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                        this.props.actions.indexOf('TRANSFER') >= 0 ||
                        this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                        this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                    <TableCell className={this.props.classes.searchRow}> <div style={{width: '100px'}}> </div> </TableCell>
                }
            </TableRow>
        )
    };
    /**
     * Sticky Table Row Entry
     */
    stickyTableDataRow = () => {
       
        return (
            <React.Fragment>
                {this.props.data.map((item, index) => {
                     let boxShadow = (this.state.selectedRow == index) ? this.props.classes.tableRowBoxShadow : '';
                     return <TableRow key={index} className={`${this.props.classes.tableRow} ${boxShadow}`}>
                     {
                         this.stickyTableDataRowValue(item, index)
                     }
                 </TableRow>
                }
            )}
            </React.Fragment>
        )
    };
    /**
     * Value entry insticky table row
     * @param {*} item 
     * @param {*} rowIndex 
     * @returns 
     * 
     * [1]  -   check the validation color and applying the style
     */
    stickyTableDataRowValue = (item, rowIndex) => {
        var y = history.location.pathname.split('/')
        var doesLoan = y.includes('loanProjects')
        let border_color = (this.state.selectedRow == rowIndex && this.props.isError) ? this.props.classes.tablePadding : '';
        return (
            <React.Fragment>
                {/* {this.props.moreInfoIcons.length > 0 &&
                    this.bindMoreInfoIcons(item)
                } */}
                {this.props.actions && this.state.selectedRow == rowIndex &&
                    (this.props.actions.indexOf('COPY') >= 0 ||
                        this.props.actions.indexOf('DELETE') >= 0 ||
                        this.props.actions.indexOf('TRANSFER') >= 0 ||
                        this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                        this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                        this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                        this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                    <TableCell
                    key={'value-' + '1'}
                    style={{height: '52px'}}
                    className={border_color}
                    >
                         {this.addAction(item,rowIndex)}
                    </TableCell>
                    
                }
            </React.Fragment>
        )
    };
    /**
     * Scroll Table Row Entry
     */
    tableDataRow = () => {
        let {selectedRow} = this.state;
        
        return (
            <React.Fragment>
                {this.props.data.map((item, index) =>{
                    let border_color = (selectedRow == index && this.props.isError) ? this.props.classes.borderValidation : '';
                    let boxShadow = (selectedRow == index) ? this.props.classes.tableRowBoxShadow : '';
                    return  <TableRow key={index}  
                    // onMouseEnter={this.handleMouseEnter}
                    // onMouseLeave={this.handleMouseLeave}
                    className={`${border_color} ${this.props.classes.tableRow} ${boxShadow}`}
                    style={{
                       backgroundColor: selectedRow === index ? '#fff' : 'white',
                       cursor: selectedRow === index ? "pointer" : "default",
                       pointerEvents: selectedRow !== null && selectedRow !== index ? 'none' : 'auto',
                       opacity: selectedRow !== null && selectedRow !== index ? 0.5 : 1, 
                    //    boxShadow:selectedRow === index && this.state.isRowHovered ? '0 4px 9px rgba(0, 0, 0, 0.27)' : null
                     }}
                    >
                       {
                           this.tableDataRowValue(item, index)
                       }
                   </TableRow>
                }
                )}
            </React.Fragment>
        )
    };
    /**
     * Value entry in Scroll table row
     * @param {*} item 
     * @param {*} rowIndex 
     * @returns 
     * 
     * [1]  -   check the validation color and applying the style
     */
    tableDataRowValue = (item, rowIndex) => {
        return (
            <React.Fragment>
                {this.props.moreInfoIcons.length > 0 &&
                    this.bindMoreInfoIcons(item)
                }
                {this.props.field.map((field, index) => {
                    // [1]
                   
                    let border_color = (this.state.selectedRow == rowIndex && this.props.isError) ? this.props.classes.tablePadding : '';
                    let validation_color = (this.props.highlightField.indexOf(field) > -1 && this.state.selectedRow == rowIndex && this.props.isError) ? constantColors[3] : '';
                    const boldText = this.props.boldText.indexOf(field) > -1 && item['140Per'] === true ? 'bolderText' : '';
                    return this.props.links && this.props.links.indexOf(field) >= 0
                        ? <TableCell
                            title={this.bindToolTipValue(item, field)}
                            key={'value-' + index}
                            className={`linkCell ${validation_color}`}
                            onClick={() => this.handleNavigateAction(item, field)}
                        >
                            {item.key}  <div className="m-r-8"> {this.bindSelectField(item, field, rowIndex)} </div>
                        </TableCell>
                        : <TableCell
                            title={this.bindToolTipValue(item, field)}
                            key={'value-' + index}
                            className={`${validation_color} ${boldText} ${border_color}`}
                        >
                            <div className="m-r-8"> {this.bindSelectField(item, field, rowIndex)} </div>
                        </TableCell>
                }
                )}
                 {this.props.actions &&
                    (this.props.actions.indexOf('COPY') >= 0 ||
                        this.props.actions.indexOf('DELETE') >= 0 ||
                        this.props.actions.indexOf('TRANSFER') >= 0 ||
                        this.props.actions.indexOf('SUBMIT') >= 0 ||
                        this.props.actions.indexOf('UNSUBMIT') >= 0 ||
                        this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                        this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                        this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                        this.props.actions.indexOf('CLAIM') >= 0 ||
                        this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                        <TableCell 
                        className={ this.state.selectedRow == rowIndex  ? this.props.classes.actionButtonSelected : this.props.classes.actionButton}
                        // style={ this.state.selectedRow == rowIndex && this.state.isAtEnd ? {display: 'table-cell !important', position: 'sticky !important', right: '0 !important', background: 'linear-gradient(to right, #fefefe, #ffffff)'} : {}}
                        >
                            { this.addAction(item, rowIndex)}
                        </TableCell>
                   
                } 
            </React.Fragment>
        )
    };
    /**
     * Bind More Info Icons
     * @param {*} item 
     * @returns 
     */
    bindMoreInfoIcons = (item) => {
        return (
            <TableCell className={'noPadding'} >
                <div className={'tblMoreInfoIconCel'}>
                    {this.props.moreInfoIcons.indexOf('ROLE') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('HOME') >= 0 && item.isHomeProject !== undefined &&
                                item.isHomeProject === true ?
                                <span className='homeCls visible'>
                                    <img src={HomeIcon} title={item.setAsideType} alt='Home Program' />
                                </span>
                                : <span className='homeCls hidden'>
                                    <img src={HomeIcon} title='Home Program' alt='Home Program' />
                                </span>
                            }
                        </React.Fragment>
                    }
                    {this.props.moreInfoIcons.indexOf('ROLE') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('NHTF') >= 0 && item.isNHTFProject !== undefined &&
                                item.isNHTFProject === true ?
                                <span className='homeCls visible'>
                                    <img src={NHFTIcon} title={item.setAsideTypeNHTF} alt='NHTF Program' />
                                </span>
                                : <span className='homeCls hidden'>
                                    <img src={NHFTIcon} title='NHTF Program' alt='NHTF Program' />
                                </span>
                            }
                        </React.Fragment>
                    }
                    {this.props.moreInfoIcons.indexOf('HOME') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('ACTIVE') >= 0 && item.isActive !== undefined
                                && item.isActive === false ?
                                <span className='activeCls visible'>*</span>
                                : <span className='activeCls hidden'>*</span>

                            }
                            {this.props.moreInfoIcons.indexOf('ROLE') >= 0 && item.role !== undefined &&
                                item.role === userRoleConstants.organizationAdmin ?
                                <span className='roleCls visible' title='Organization Admin'>
                                    <i className="fa fa-shield" aria-hidden="true"></i>
                                </span>
                                : <span className='roleCls hidden' title='Organization Admin'>
                                    <i className="fa fa-shield" aria-hidden="true"></i>
                                </span>
                            }
                        </React.Fragment>
                    }
                </div>
            </TableCell>
        )
    }
    /**
     * Adding action
     * @param {*} item 
     * @returns 
     * 
     * [1]  -   filter out DELETE & COPY & TRANSFER
     * [2]  -   for table 1
     * [3]  -   for contractActivity
     */
    addAction = (item, rowIndex) => {
        const { setLabel, deleteTooltip, actionClassName, classes } = this.props;
        let actions = this.props.actions.slice();
        if (item.disabled) {
            // [1]
            actions = actions.filter(act => { return act !== 'DELETE' && act !== 'TRANSFER'; });
        }
        // [2]
        if (this.props.actions.indexOf('DELETE') >= 0 && item.allowDelete) {
            actions.push('DELETE');
        }
        // [3]
        if(!item.allowDeleteContractActivity&&history.location.pathname.includes('contractActivity')){
            actions = actions.filter(act=>{return act!=='DELETE'})
        }
        
        return (
            <TableCell key={'actions'} className={this.props.classes.lastCol}>
                <div className={actionClassName}>
                    {actions.indexOf('COPY') >= 0 &&
                        <ToolTip title="Copy" placement="bottom">
                            <IconButton  className="copy_icon" color="primary" aria-label="Copy" onClick={() => this.props.handleTableCopyAction(item._id)}>
                                <CopyIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('TRANSFER') >= 0 &&
                        <ToolTip title="Transfer" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Download" onClick={() => this.props.handleTransferAction(item)}>
                                <TransferIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('VIEW_REPORT') >= 0 &&
                        <ToolTip title={setLabel ? setLabel(item, "View Report") : "View Report"} placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="View" onClick={() => this.props.handleViewReportAction(item)}>
                                <TableIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('REPORT_DOWNLOAD') >= 0 &&
                        <ToolTip title="Download Report" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Download" onClick={() => this.props.handleDownloadAction(item)}>
                                <DownloadIcon />
                            </IconButton>
                        </ToolTip>
                    }

                    {       
                     actions.indexOf('SAVE') >= 0 &&
                     <div className={ ( (this.state.selectedRow != null && this.state.selectedRow == rowIndex) && ( (this.props.isEnableSave || item['isUpdated'] == true) && !this.props.isError )) ? '' : classes.actionItems}>
                        <ToolTip title="Save" placement="bottom">
                            <IconButton  className="save_icon" color="primary" aria-label="Save" onClick={() => this.props.handleSaveAction(item, rowIndex, () => { this.setState({selectedRow:null}) })}>
                                <InspectionSaveIcon/>
                            </IconButton>
                        </ToolTip>
                    </div>
                    }

                    {
                     actions.indexOf('CANCEL') >= 0 && 
                     <div className={( (this.state.selectedRow != null && this.state.selectedRow == rowIndex) && this.props.isEnableCancel ) ? '' : classes.actionItems}>
                        <ToolTip title="Cancel" placement="bottom">
                            <IconButton className="cancel_icon" color="primary" aria-label="Cancel" onClick={() => this.handleCancelClickOpen(item.recordId, rowIndex)}>
                                <InspectionCancelIcon/>
                            </IconButton>
                        </ToolTip>
                    </div>
                    }
                   
                    { (item['isNew'] && !item['isUpdated']) && actions.indexOf('DELETE') >= 0 &&
                        <ToolTip title={deleteTooltip} placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Delete" onClick={() => this.handleDeleteClickOpen(item.recordId, item)}>
                                <InspectionDeleteIcon />
                            </IconButton>
                        </ToolTip>
                    }
                </div>
            </TableCell>
        )
    };
    /*
        Sticky Table
    */
    stickyTable = () => {
        const { data, searchVisible, stickyHeaders } = this.props;
        return (
            <Table stickyHeader aria-label="sticky table"  className={this.props.isError ? '' : this.props.classes.inspectingTable}>
                <TableHead>
                    { (stickyHeaders.length > 0 )&&
                        this.stickyTableHeaderRow()
                    } 
                </TableHead>
                <TableBody>
                    {searchVisible && stickyHeaders.length > 0 &&
                        this.stickyTableFilterRow()
                    }

                    {data && data.length > 0 &&
                        this.stickyTableDataRow()
                    }
                </TableBody>
            </Table>
        )
    }

    /*
        Scroll Table
    */
    scrollTable = () => {
        const { data, searchVisible, header, tableHeaderColor } = this.props;
        let headerClass = tableHeaderColor === 'grey' ? 'th-light-grey' : '';
        return (
            <Table aria-label="sticky table" className={this.props.isError ? '' : this.props.classes.inspectingTable}>
                <TableHead className={headerClass}>
                    {header.length > 0 &&
                        this.tableHeaderRow()
                    }
                </TableHead>
                <TableBody>
                    {searchVisible && header.length > 0 &&
                        this.tableFilterRow()
                    }

                    {data && data.length > 0 &&
                        this.tableDataRow()
                    }
                </TableBody>
            </Table>
        )
    }

    handleMouseEnter = () => {
        this.setState({ isRowHovered: true });
    };
    
    handleMouseLeave = () => {
        this.setState({ isRowHovered: false });
    };
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, data, rowsPerPage, page, count, paginationVisible, noDataErrorMessage, isLoading, header, stickyHeaders, showLoading, prevNext, showMsgInside, tableHeight, primaryHeader } = this.props;
        const itemData = this.state.itemData || {};
        let style = primaryHeader.length > 0 ? { height: "100%" } : { padding: '0px 10px', height: "100%" }
        return (
             <>
                <TableContainer className={`${classes.container} ${classes.tableContainer}`}>
                    <div className="list-table">
                    {
                        <>
                            {header.length > 0 && <div ref={this.tableRef} className={stickyHeaders.length > 0 ? "scroll-table" : "scroll-table"} style={{ height: data.length > 6 ? 'unset'  : `${window.innerHeight * 0.75}px` }}>
                            {this.scrollTable()}
                            <React.Fragment>
                                { data && data.length <= 0 && isLoading == false &&
                                    <div className={showMsgInside === true ? 'nocontent-inside' : 'nocontent'}>
                                        {noDataErrorMessage}
                                    </div>
                                }
                            </React.Fragment>

                            </div>}
                            {/* {(stickyHeaders.length > 0) && <div className="sticky-table">
                                { data.length > 0 && this.stickyTable()}
                            </div>} */}
                        </>
                    }
                    </div>
                </TableContainer>
                {/* } */}
                {/* {(header.length <= 0) && data && data.length <= 0 && isLoading === true && showLoading === true &&
                    <div className={'loader'}>
                        <div className="svgloader">
                            <SvgLoadIcon> </SvgLoadIcon>
                        </div>
                    </div>
                } */}
                {
                     <PageLoader show={isLoading && showLoading}/>
                }
                <div>
                    {/* <React.Fragment>
                        { data && data.length <= 0 && isLoading == false &&
                            <div className={showMsgInside === true ? 'nocontent-inside' : 'nocontent'}>
                                {noDataErrorMessage}
                            </div>
                        }

                    </React.Fragment> */}
                    {data && data.length > 0 && paginationVisible  &&
                        <Pagination
                            style={{ padding: '0px 6px' }}
                            activePage={page}
                            itemsCountPerPage={rowsPerPage}
                            totalItemsCount={count}
                            pageRangeDisplayed={5}
                            onChange={this.handleChangePage}
                            isLoading={false}
                        />}

                    {
                        data && data.length > 0 && prevNext && (
                            <PrevNext
                                onClick={(isNext) => {
                                    if (isNext) {
                                        this.props.onChangePageTable(page + 1, rowsPerPage);
                                    } else {
                                        if (page > 0) {
                                            this.props.onChangePageTable(page - 1, rowsPerPage);
                                        }
                                    }
                                }}
                                isLoading={isLoading}
                            />
                        )
                    }
                    <DeleteDialog
                        open={this.state.open}
                        content={itemData.isLastOA ? "This is the last Administrator contact for this organization! Are you sure you want to delete this contact?" : this.state.deleteTitle}
                        handleDeleteAction={this.handleTableDeleteAction}
                        id={this.state.deleteItemId}
                        handleDeleteOnCloseAction={this.handleTableDeleteOnCloseAction} />
                </div>
             </>
        );
    }
}
//prop types
InspectionTableComponent.propTypes = {
    data: PropTypes.any.isRequired,
    header: PropTypes.any.isRequired,
    field: PropTypes.any.isRequired,
    search: PropTypes.any.isRequired,
    actions: PropTypes.any,
    links: PropTypes.any,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    onChangePageTable: PropTypes.func.isRequired,
    onSortRequest: PropTypes.func.isRequired,
    onInputChangeRequest: PropTypes.func,
    resetSearchContent: PropTypes.bool.isRequired,
    paginationVisible: PropTypes.bool.isRequired,
    handleTableDeleteAction: PropTypes.func,
    handleNavigateAction: PropTypes.func,
    userOrgId: PropTypes.any,
    userRole: PropTypes.any,
    highlighColor: PropTypes.any,
    highlightField: PropTypes.any,
    noDataErrorMessage: PropTypes.string.isRequired,
    currentPage: PropTypes.string,
    removedSortingRow: PropTypes.array,
    isLoading: PropTypes.bool,
    searchVisible: PropTypes.bool,
    showLoading: PropTypes.bool,
    moreInfoIcons: PropTypes.array,
    handleTransferAction: PropTypes.func,
    handleDownloadAction: PropTypes.func,
    boldText: PropTypes.array,
    showMsgInside: PropTypes.bool,
    multiHeader: PropTypes.bool,
    staticSpan: PropTypes.number,
    isEnableSave: PropTypes.bool,
    isEnableCancel : PropTypes.bool,
    isError: PropTypes.bool,
    handleFilterRowClick:  PropTypes.func,
    funderList: PropTypes.array,
};
//default types
InspectionTableComponent.defaultProps = {
    data: [],
    primaryHeader: [],
    header: [],
    field: [],
    search: [],
    page: 0,
    showLoading: true,
    rowsPerPage: 0,
    count: 0,
    orderBy: '',
    order: 'asc',
    classes: {},
    sFields: [],
    sValues: [],
    onChangePageTable: () => { },
    onSortRequest: () => { },
    onInputChangeRequest: () => { },
    resetSearchContent: false,
    paginationVisible: false,
    handleTransferAction: () => { },
    handleTableDeleteAction: () => { },
    handleNavigateAction: () => { },
    componentMap: {},
    userOrgId: '',
    userRole: '',
    actions: [],
    handleTableCopyAction: () => { },
    highlightField: [],
    noDataErrorMessage: '',
    currentPage: '',
    removedSortingRow: [],
    isLoading: false,
    searchVisible: true,
    config: {},
    handleViewReportAction: () => { },
    handleDownloadAction: () => { },
    moreInfoIcons: [],
    style: {},
    innerStyle: {},
    boldText: [],
    prevNext: false,
    showMsgInside: false,
    ellipsis: true,
    getDeleteTitle: null,
    setLabel: null,
    deleteTooltip: 'Delete',
    actionClassName: 'actions-center',
    stickyHeaders: [],
    headers: [],
    multiHeader: false,
    tableHeight: "",
    tableHeaderColor: '',
    staticSpan: null,
    isDeleteRequired:true,
    value:'',
    selectedRow: null,
    handleRowClick:() => {},
    onDropDownChangeRequest:() => {},
    handleFilterRowClick: () => {},
    isEnableSave: false,
    isEnableCancel: false,
    isError: false,
    funderList:[]
}
//export component
export default withStyles(tableStyle)(InspectionTableComponent);