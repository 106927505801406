import { monitoringCycleConstants } from "../constants/monitoringCycle"
import { monitoringCycleService } from "../services/monitoringCycle"
import { alertActions } from "./alert";
import { saveAs } from 'file-saver';
import { convertDateWithTimeZoneFormat } from "../helpers";
 
export const monitoringCycleActions = {
    getMonitoringCycleLogs,
    create,
    changeState,
    getSiteLogs,
    changeCheckStateData,
    changeSiteState,
    checkDuplicate,
    clear,
    changeTempState,
    download,
    getFunderList,
    clearDuplicate,
    changePageCount
}

/**
 * A method call API to create the monitoring cycle details
 *
 * @param {*} formData contains the monitoring cycle details data to create
 * @returns
 */
function create(formData, clbk = () => {}) {
    return dispatch => {
        dispatch(request());

        monitoringCycleService.create(formData)
            .then(
                data => {
                    dispatch(success(data));
                    clbk(data)
                    dispatch(alertActions.success("Monitoring Cycle Added Successfully"))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: monitoringCycleConstants.CREATE_MONITORING_CYCLE_REQUEST } }
    function success(data) { return { type: monitoringCycleConstants.CREATE_MONITORING_CYCLE_SUCCESS, data } }
    function failure(error) { return { type: monitoringCycleConstants.CREATE_MONITORING_CYCLE_FAILURE, error } }
}

function getMonitoringCycleLogs (skip, limit, orderBy, order, sFields, sValues) {

    return dispatch => {
        dispatch(request());

        monitoringCycleService.getMonitoringCycleLogs(skip, limit, orderBy, order, sFields, sValues)
        .then(monitoringCycle => {
            //    monitoringCycle.data && monitoringCycle.data.length > 0 && monitoringCycle.data.forEach(elem => {
            //     if (elem.lastModifiedAt) {
            //         // let updatedAt = new Date(elem.lastModifiedAt);
            //         // let modifiedDay = updatedAt.getDate() < 10 ? ('0' + updatedAt.getDate()) : updatedAt.getDate()
            //         // let modifiedMonth = updatedAt.getMonth() + 1 < 10 ? ('0' + (updatedAt.getMonth() + 1)) : updatedAt.getMonth() + 1
            //         // let modifiedHours = updatedAt.getHours() < 10 ? ('0' + (updatedAt.getHours())) : updatedAt.getHours()
            //         // let modifiedTime = updatedAt.getMinutes() < 10 ? ('0' + updatedAt.getMinutes()) : updatedAt.getMinutes();
            //         // let modifiedSeconds = updatedAt.getSeconds() < 10 ? ('0' + updatedAt.getSeconds()) : updatedAt.getSeconds();
            //         // let formatModifiedDate = `${modifiedMonth}/${modifiedDay}/${updatedAt.getFullYear()}, ${modifiedHours}:${modifiedTime}:${modifiedSeconds}`;
            //         elem.lastModifiedAt = convertDateWithTimeZoneFormat(elem.lastModifiedAt);
            //     }
            //    })
               dispatch(success(monitoringCycle))
            },
            error => {
                dispatch(failure(error.toString()))
            }
        )

    }

    function request() { return {type: monitoringCycleConstants.GETMONITORINGCYCLELOGS_REQUEST}}
    function success(monitoringCycle) { return {type: monitoringCycleConstants.GETMONITORINGCYCLELOGS_SUCCESS,monitoringCycle}}
    function failure(error) { return {type: monitoringCycleConstants.GETMONITORINGCYCLELOGS_FAILURE,error}}
}

function download (data,fileName = "WBARS_Inspection") {

    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait... Download is Inprogress'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)

        monitoringCycleService.downloadCycle(data)
        .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, `${fileName}_${new Date().getFullYear()}`);
                    dispatch(success());
                },
                error => dispatch(failure(error.toString()))
            );

    }

    function request() { return {type: monitoringCycleConstants.DOWNLOAD_REQUEST}}
    function success(monitoringCycle) { return {type: monitoringCycleConstants.DOWNLOAD_SUCCESS,monitoringCycle}}
    function failure(error) { return {type: monitoringCycleConstants.DOWNLOAD_FAILURE,error}}
}


function getSiteLogs (skip, limit, orderBy, order, sFields, sValues, callBack ) {
    
    return dispatch => {
        dispatch(request());

        monitoringCycleService.getAllSiteLogs(skip, limit, orderBy, order, sFields, sValues, callBack)
        .then(
            siteLogs => {
                dispatch(success(siteLogs))
                callBack()
            },
            error => {
                dispatch(failure(error.toString()))
            }
        )
    }

    function request() { return {type: monitoringCycleConstants.GET_ALL_SITE_LOGS_REQUEST}}
    function success(siteLogs) { return {type: monitoringCycleConstants.GET_ALL_SITE_LOGS_SUCCESS,siteLogs}}
    function failure(error) { return {type: monitoringCycleConstants.GETMONITORINGCYCLELOGS_FAILURE,error}}

}

function checkDuplicate (formData, clbk) {
    return dispatch => {
        dispatch(request());

        monitoringCycleService.checkDuplicate(formData)
            .then(
                duplicateRec => {
                    dispatch(success(duplicateRec));
                    clbk();
                   // dispatch(alertActions.success("Monitoring Cycle Added Successfully"))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: monitoringCycleConstants.CHECK_DUPLICATE_REQUEST } }
    function success(duplicateRec) { return { type: monitoringCycleConstants.CHECK_DUPLICATE_SUCCESS, duplicateRec } }
    function failure(error) { return { type: monitoringCycleConstants.CHECK_DUPLICATE_FAILURE, error } }
}

function clear (id, clbk) {
        return dispatch => {
            dispatch(request(id));
    
            monitoringCycleService.clear(id)
                .then(
                    explore => {
                        dispatch(success(id));
                        clbk();
                        dispatch(alertActions.success('Deleted successfully'));
                    },
                    error => {
                        dispatch(failure(id, error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
        };
    
        function request(id) { return { type: monitoringCycleConstants.DELETE_REQUEST, id } }
        function success(id) { return { type: monitoringCycleConstants.DELETE_SUCCESS, id } }
        function failure(id, error) { return { type: monitoringCycleConstants.DELETE_FAILURE, id, error } }
}

function getFunderList () {
    return dispatch => {
        dispatch(request());

        monitoringCycleService.getFunderList()
            .then(
                funderList => {
                    dispatch(success(funderList));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: monitoringCycleConstants.GET_FUNDER_LIST_REQUEST } }
    function success(funderList) { return { type: monitoringCycleConstants.GET_FUNDER_LIST_SUCCESS, funderList } }
    function failure(error) { return { type: monitoringCycleConstants.GET_FUNDER_LIST_ERROR, error } }
}



function changeState(updatedData) {
    return (dispatch) => {
        dispatch(success(updatedData))
    };
    function success(updatedData) { return { type: monitoringCycleConstants.CHANGE_MONITORING_CYCLE_LOGS, updatedData } }
}


function changeTempState(updatedData) {
    return (dispatch) => {
        dispatch(success(updatedData))
    };
    function success(updatedData) { return { type: monitoringCycleConstants.CHANGE_TEMP_MONITORING_CYCLE_LOGS, updatedData } }
}

function changeCheckStateData(updatedData) {
    return (dispatch) => {
        dispatch(success(updatedData))
    };
    function success(updatedData) { return { type: monitoringCycleConstants.CHANGE_CHECKED_STATE, updatedData } }
}


function changeSiteState(updatedData) {
    return (dispatch) => {
        dispatch(success(updatedData))
    }
    function success(updatedData) { return { type: monitoringCycleConstants.CHANGE_ALL_SITE_LOGS, updatedData  } }
}

function clearDuplicate() {
    return(dispatch) => {
        dispatch(success())
    }
    function success() { return {type: monitoringCycleConstants.CLEAR_DUPLICATE_FLAG} }
}

function changePageCount(pageDetails) {
    return(dispatch) => {
        dispatch(success(pageDetails))
    }
    function success() { return {type: monitoringCycleConstants.CHANGE_PAGE_DETAILS, pageDetails} }
}