import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions
import { sitesActions } from "../../actions/sites";
import { confActions } from "../../actions/configuration";

// Import Helpers
import { getUserRole, roleAccess, getUserId, getUserOrgId, formatDate,convertDateWithTimeZone } from '../../helpers';
import { configurationConstants, SitesConstants } from "../../helpers/appConstants";
import { history } from '../../helpers'

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import DatePicker from '../../components/DatePicker/DatePicker';
import SaveCancel from '../../components/SaveCancel/SaveCancel';

// import global validation requirements
import ValidatorForm from "../../helpers/fieldValidations";

import ToolTip from '../../components/ToolTip/ToolTip';
import { InfoIcon } from '../../components/SvgIcons/SvgIcons';
/**
 * SitesEdit Component
 * @class SitesEdit
 * @extends {Component}
 */
class SitesEdit extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            project: {
                id: props.projectId
            },
            name: '',
            prevName: '',
            sitesId: '',
            desc: '',
            sitekey: '',
            sitetypesorting: '',
            monitoringstatus: '',
            prevMonitoringStatus: '',
            servicedate: '',
            grossrent: '',
            county: null,
            sitepropertymanager: {
                id: null,
                name: '',
                phone: '',
                email: '',

            },
            organization: {},
            phone: '',
            email: '',
            customreport: SitesConstants.custom_report[0].value,
            onsiteManager: {
                id: null,
                name: '',
                phone: '',
                email: '',
            },
            onsitemanagerco: {},
            onsitephone: '',
            onsiteemail: '',
            taxparcelIds: '',
            taxparcel: [],
            funders: '',
            totalbuildings: '',
            totalunits: '',
            oid: '',
            prevCounty: null,
            prevServiceDate: '',
            isSiteTable1List: false,
            toolTipTitle: ''
        };
        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.pageAccess = roleAccess[this.userRole]['siteview'].slice();
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { isArchived } = this.props;
        if (this.props.siteId) {
            this.props.getById(this.props.siteId, isArchived ? 'archives' : null);
            this.props.getSiteByTable1List(this.props.siteId);
        }
        this.props.getAllOrg('', 'sites');
        this.props.getAllOrgOnsite('', 'sites');
        this.props.getProjectsByName(null, isArchived ? 'archives' : null);

        //Get Config Values
        this.props.getConfiguration(configurationConstants.counties);
        this.props.getConfiguration(configurationConstants.siteTypeForSorting);
        this.props.getConfiguration(configurationConstants.monitoringStatus);
    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { sites, getOne, isSiteTable1List} = nextProps;
        if (sites && getOne.data && this.props.siteId) {
            if (getOne.data._id !== this.state.sitesId) {
                // pjt manager
                let projectManagerOrg = { ...getOne.data.projectManager.org_id };
                let projectManager = { ...getOne.data.projectManager.user_id };
                let onsiteManagerOrg = { ...getOne.data.onsiteManager.org_id };
                let onsiteManager = { ...getOne.data.onsiteManager.user_id };
                let project = { ...getOne.data.project_id };
                // update for autocomplete
                if (project._id) {
                    project.id = project._id;
                    project.value = project._id;
                    project.label = project.name;
                }
                if (projectManager._id) {
                    projectManager.id = projectManager._id;
                    projectManager.value = projectManager._id;
                    projectManager.name = `${projectManager.isActive ? '' : '*'}${projectManager.firstName} ${projectManager.lastName}`;
                    projectManagerOrg.id = projectManagerOrg._id;
                    projectManagerOrg.value = projectManagerOrg._id;
                    projectManagerOrg.label = projectManagerOrg.name;
                }
                if (onsiteManager._id) {
                    onsiteManager.id = onsiteManager._id;
                    onsiteManager.value = onsiteManager._id;
                    onsiteManager.name = `${onsiteManager.isActive ? '' : '*'}${onsiteManager.firstName} ${onsiteManager.lastName}`;
                    onsiteManagerOrg.id = onsiteManagerOrg._id;
                    onsiteManagerOrg.value = onsiteManagerOrg._id;
                    onsiteManagerOrg.label = onsiteManagerOrg.name;
                }
                this.setState({
                    sitesId: getOne.data._id,
                    project: { ...project },
                    name: getOne.data.name,
                    prevName: getOne.data.name,
                    desc: getOne.data.desc || '',
                    toolTipTitle: getOne.data.toolTip,
                    servicedate: formatDate(getOne.data.placedInServiceDate),
                    prevServiceDate: formatDate(getOne.data.placedInServiceDate),
                    grossrent: formatDate(getOne.data.grossRentFloorDate),
                    sitekey: getOne.data._id,
                    sitetypesorting: getOne.data.siteTypeForSorting_id ? parseInt(getOne.data.siteTypeForSorting_id, 10) : '',
                    prevMonitoringStatus: getOne.data.monitoringStatus_id ? parseInt(getOne.data.monitoringStatus_id, 10) : '',
                    monitoringstatus: getOne.data.monitoringStatus_id ? parseInt(getOne.data.monitoringStatus_id, 10) : '',
                    customreport: getOne.data.type_id,
                    county: getOne.data.county,
                    prevCounty: getOne.data.county,
                    phone: getOne.data.projectManager.user_id && getOne.data.projectManager.user_id.phone ? getOne.data.projectManager.user_id.phone : '',
                    email: getOne.data.projectManager.user_id && getOne.data.projectManager.user_id.email ? getOne.data.projectManager.user_id.email : '',
                    sitepropertymanager: projectManager._id ? { ...projectManager } : {},
                    onsiteManager: onsiteManager._id ? { ...onsiteManager } : {},
                    organization: { ...projectManagerOrg },
                    onsitemanagerco: { ...onsiteManagerOrg },
                    taxparcelIds: getOne.data.taxParcel,
                    funders: getOne.data.funders,
                    oid: getOne.data.contractOrgs,
                    totalbuildings: getOne.data.buildings,
                    totalunits: getOne.data.units,
                    isSiteTable1List: isSiteTable1List
                });
            }
        }
        if (this.props.projectId) {
            this.setState({
                project: { id: parseInt(this.props.projectId, 10) }
            });
        }
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    }
    /**
     * county autocomplete
     * @param {*} selectedOption 
     */
    _handleAutoComplete = (selectedOption) => {
        this.setState({ county: selectedOption.value });
    }
    /**
     * Handle Auto Complete
     * @param {*} selectedOption 
     */
    handleAutoCompolete = (selectedOption) => {
        let organization = {
            id: selectedOption.value,
            name: selectedOption.label
        };
        this.setState({ organization: organization, sitepropertymanager: { id: null, value: null } });
        if (selectedOption.value) {
            this.props.getAllUsersInOrg(selectedOption.value);
        }
    }
    /**
     * Handle Autocomplete User
     * @param {*} selectedOption 
     */
    handleAutoCompleteUser = (selectedOption) => {
        if (selectedOption.length === 0) {
            this.setState({ sitepropertymanager: { id: null, name: '', value: '', label: '', phone: '' } });
        }
        else {
            this.setState({ sitepropertymanager: { ...selectedOption } });
        }
    }
    /**
     * Handle Autocomplete Site
     * @param {*} selectedOption 
     */
    handleAutoCompleteonsite = (selectedOption) => {
        let onsitemanagerco = {
            id: selectedOption.value,
            name: selectedOption.label
        };
        this.setState({ onsitemanagerco: onsitemanagerco, onsiteManager: { id: null, value: null } });
        if (selectedOption.value) {
            this.props.getAllUsersInOrgwithrole(selectedOption.value);
        }
    }
    /**
     * Handle Autocomplete Onsite Mangaer
     * @param {*} selectedOption 
     */
    handleAutoCompleteonsitemanager = (selectedOption) => {
        if (selectedOption.length === 0) {
            this.setState({ onsiteManager: { id: null, name: '', value: '', label: '', phone: '' } });
        }
        else {
            this.setState({ onsiteManager: { ...selectedOption } });
        }
    }
    /**
     * Handle Form Submit Event
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /** Save Form */
    _saveForm() {
        const { sitepropertymanager, organization, onsitemanagerco, onsiteManager } = this.state;
        // set up api form submission datas
        const generalData = {
            _id: this.state.sitesId,
            // project_id: this.state.project.value,
            project_id: this.state.project.id,
            name: this.state.name,
            desc: this.state.desc,
            type_id: this.state.customreport,
            siteTypeForSorting_id: this.state.sitetypesorting,
            monitoringStatus_id : this.state.monitoringstatus,
            county: this.state.county,
            projectManager: {
                org_id: organization.id || 0,
                org_name: organization.name || '',
                user_id: sitepropertymanager.value || 0,
                name: `${sitepropertymanager.firstName || ''} ${sitepropertymanager.lastName || ''}`
            },
            onsiteManager: {
                org_id: onsitemanagerco.id || 0,
                org_name: onsitemanagerco.name || '',
                user_id: onsiteManager.value || 0,
                name: `${onsiteManager.firstName || ''} ${onsiteManager.lastName || ''}`
            },
            taxParcel: this.state.taxparcelIds,
            oid: this.state.oid,
            placedInServiceDate: this.state.servicedate,
            grossRentFloorDate: this.state.grossrent
        };

        const checkFieldsChanged = (((this.state.county != this.state.prevCounty) || (this.state.servicedate != this.state.prevServiceDate)) && this.state.isSiteTable1List) ? true :  false;
        const checkCycleChanged = this.state.monitoringstatus != this.state.prevMonitoringStatus;
        const checkNameChanged = this.state.name != this.state.prevName;
        // console.log("----------------------------CHECK FIELDS CHANGED--------------------", checkFieldsChanged)
        this.props.handleFormSaveActionClick({ ...generalData, 
                                                isCycleChanged: checkCycleChanged,
                                                isNameChanged: checkNameChanged,
                                                prevCycleId: this.state.prevMonitoringStatus
                                               },checkFieldsChanged);
        this.setState({
            prevCounty: this.state.county,
            prevServiceDate: this.state.servicedate,
            prevMonitoringStatus: this.state.monitoringstatus,
            prevName: this.state.name,
        })
    }
    /**
     * Bind Html to DOM 
     */
    render() {

        // Monitoring Cycle Info:

        let { classes, sites, isReadOly, pageAccess, counties, siteTypeForSorting, monitoringStatus } = this.props;
        const { orgs, users, onsiteUsers, orgsOnsite } = sites.getOne;
        const { project, name, desc, sitekey, sitetypesorting, monitoringstatus, servicedate, grossrent, oid, county, sitepropertymanager, organization, onsiteManager, onsitemanagerco, totalbuildings, totalunits, customreport, taxparcelIds } = this.state;
        let editContactOnly = pageAccess.indexOf('EDIT_CONTACTS_NAME_ONLY') !== -1;
        let editOrgsOnly = pageAccess.indexOf('EDIT_ORGS') !== -1;
        let noEdit = pageAccess.indexOf('NO_EDIT') !== -1;
        let viewMC = pageAccess.indexOf('VIEW MC') !== -1;
        let editMC = pageAccess.indexOf('EDIT MC') !== -1;
        isReadOly = isReadOly ? isReadOly : editContactOnly;
        if (!isReadOly && !noEdit) {
            // limited access
            if (editContactOnly || editOrgsOnly) {
                isReadOly = true;
            } else {
                // for funder level
                editContactOnly = true;
                editOrgsOnly = true;
            }
        } else if (!isReadOly && noEdit) {
            // for prop and onsite
            editContactOnly = false;
            editOrgsOnly = false;
            isReadOly = true;
        } else { }
        return (
            <div className={classes.rootEdit} >
                <ValidatorForm
                    name="siteForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                suggestions={sites.getOne.projects}
                                name="project"
                                className="autocomplete-fixed dotted"
                                handleAutoCompolete={(selectedOption) => {
                                    this.setState({ project: selectedOption });
                                }}
                                selectedValue={project}
                                disabled={isReadOly || (this.props.siteId !== undefined && this.props.siteId !== null) || (this.props.projectId !== undefined && this.props.projectId !== null)}
                                placeholder="Project Name"
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="name"
                                value={name}
                                label="Site Name"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="sitekey"
                                value={sitekey}
                                label="Site Key"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="desc"
                                value={desc}
                                label="Description"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                name="customreport"
                                placeholder='Custom Report Type:'
                                className="autocomplete-fixed"
                                suggestions={SitesConstants.custom_report}
                                handleAutoCompolete={(selectedOption) => {
                                    this.setState({
                                        customreport: selectedOption.value
                                    });
                                }}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                selectedValue={{ id: customreport }}
                                defaultValue='Default'
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                name="sitetypesorting"
                                className="autocomplete-fixed"
                                placeholder='Site Type for Sorting'
                                suggestions={siteTypeForSorting}
                                handleAutoCompolete={(selectedOption) => {
                                    this.setState({
                                        sitetypesorting: selectedOption.value
                                    })
                                }}
                                selectedValue={{ id: sitetypesorting }}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <DatePicker
                                name="servicedate"
                                value={servicedate}
                                label="Placed in Service Date"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        {
                            viewMC && <Grid item xs={12} sm={3}>
                                <div>
                                    <AutoComplete
                                        name="monitoringcycle"
                                        className="autocomplete-fixed"
                                        placeholder='Monitoring Cycle'
                                        suggestions={monitoringStatus}
                                        handleAutoCompolete={(selectedOption) => {
                                            this.setState({
                                                monitoringstatus : selectedOption.value || ""
                                            })
                                        }}
                                        selectedValue={{ id: monitoringstatus }}
                                        disabled={isReadOly || !editMC}
                                    /> 
                                </div>
                             {/* <div className={'posRelative'}>
                                 <span className={'ddTooltip'}>
                                    <ToolTip title={this.state.toolTipTitle} placement="top">
                                        <span><InfoIcon /></span>
                                    </ToolTip>
                                 </span>

                                 <div className={'p-l-20'}>
                                    <AutoComplete
                                        name="monitoringcycle"
                                        className="autocomplete-fixed"
                                        placeholder='Monitoring Cycle'
                                        suggestions={monitoringStatus}
                                        handleAutoCompolete={(selectedOption) => {
                                            this.setState({
                                                monitoringstatus : selectedOption.value || ""
                                            })
                                        }}
                                        selectedValue={{ id: monitoringstatus }}
                                        disabled={isReadOly || !editMC}
                                    /> 
                                 </div>
                             </div> */}
                           </Grid>
                         
                        }
                        <Grid item xs={12} sm={3}>
                            <DatePicker
                                name="grossrent"
                                value={grossrent}
                                label="Gross Rent Floor Date"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="oid"
                                value={oid}
                                label="Contract/OID"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                placeholder='Site County'
                                suggestions={counties}
                                className="autocomplete-fixed"
                                handleAutoCompolete={this._handleAutoComplete}
                                selectedValue={{ value: county, id: county }}
                                disabled={isReadOly}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                name="organization"
                                placeholder="Property Manager Org"
                                className="autocomplete-fixed fixOverlapContractor"
                                suggestions={orgs}
                                handleAutoCompolete={this.handleAutoCompolete}
                                selectedValue={organization}
                                disabled={!editOrgsOnly}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                suggestions={users}
                                name="sitepropertymanager"
                                handleAutoCompolete={this.handleAutoCompleteUser}
                                className="autocomplete-fixed"
                                selectedValue={sitepropertymanager}
                                disabled={!editContactOnly}
                                placeholder="Site Property Manager"
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="sitepropertymanager.phone"
                                value={sitepropertymanager.phone || ''}
                                label="Phone"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="sitepropertymanager.email"
                                value={sitepropertymanager.email || ''}
                                label="Email Address"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                suggestions={orgsOnsite}
                                handleAutoCompolete={this.handleAutoCompleteonsite}
                                selectedValue={onsitemanagerco}
                                disabled={!editOrgsOnly}
                                placeholder="Onsite Manager's Co(If Applicable)"
                                className="autocomplete-fixed fixOverlapContractor"
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                name="onsiteManagerUser"
                                suggestions={onsiteUsers}
                                handleAutoCompolete={this.handleAutoCompleteonsitemanager}
                                className="autocomplete-fixed"
                                selectedValue={onsiteManager}
                                disabled={!editContactOnly}
                                placeholder="Onsite Manager"
                                validators={[]}
                                errorMessages={[]}
                            />

                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="phone"
                                value={onsiteManager.phone}
                                label="Phone"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="email"
                                value={onsiteManager.email}
                                label="Email Address"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="taxparcelIds"
                                value={taxparcelIds}
                                label="Tax Parcel ID"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="totalbuildings"
                                value={totalbuildings || 0}
                                label="Total No of Site Buildings"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="totalunits"
                                value={totalunits || 0}
                                label="Total No of Site Units"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={true}
                            />
                        </Grid>

                    </Grid>

                    {
                        pageAccess.indexOf('SAVE') > -1 && pageAccess.indexOf('NO_EDIT') === -1 ? (
                            <SaveCancel
                                handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { history.goBack() }} />)
                            : null
                    }

                </ValidatorForm>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
SitesEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
}
/** Default Props */
SitesEdit.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    isSubPage: false
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        sites: state.sites,
        getOne: state.sites.getOne,
        isSiteTable1List: state.sites.siteTable1List,
        counties: state.configuration.configurations[configurationConstants.counties] || [],
        siteTypeForSorting: state.configuration.configurations[configurationConstants.siteTypeForSorting] || [],
        monitoringStatus: state.configuration.configurations[configurationConstants.monitoringStatus] || [],
        SitesConstants: SitesConstants.custom_report
    }
};
/**
 * Maps dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getById: (id, type) => dispatch(sitesActions.getById(id, type)),
        // getTooltipMsg: () => dispatach(sitesActions.get),
        getAllOrg: (text, page) => dispatch(sitesActions.getOrganizations(text, page)),
        getAllUsersInOrg: (orgId) => dispatch(sitesActions.getUsersInOrg(orgId)),
        getAllUsersInOrgwithrole: (orgId) => dispatch(sitesActions.getUsersInOrgwithRole(orgId)),
        getProjectsByName: (text, type) => dispatch(sitesActions.getProjectsByName(text, type)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getAllOrgOnsite: (text, page) => dispatch(sitesActions.getOrganizationsOnsite(text, page)),
        getSiteByTable1List: (siteId) => dispatch(sitesActions.getSiteByTable1List(siteId))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(SitesEdit));

