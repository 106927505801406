const tableStyle = theme => ({
    root: {
        width: '100%',
        //marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
        position: "relative"
    },
    container: {
        // maxHeight: 575,
    },
    table: {
        width: 'max-content',
        minWidth: '100%'
    },
    editTable: {
        width: '100%',
    },
    width100: {
        width: '100%'
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    MuiIconButton: {
        root: {
            fontSize: "13px",
        },
    },
    searchRow: {
        borderBottomStyle: 'solid'
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    lastCol: {
        '&:last-child': {
            paddingRight: "12px",
            textAlign: 'center'
        },
    },
    exploreEditTableTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    actionItems : {
        pointerEvents: 'none', 
        userSelect: 'none', 
        cursor: 'default',
        opacity: 0.5 
    },
    borderValidation: {
        border: '1px solid #d32f2f !important',
        borderRadius: '10px'
        // borderTop : '1px solid #d32f2f !important',
    },
    tablePadding: {
        paddingBottom: '12px !important'
    },
    disableRows: {
        pointerEvents: 'none', /* Disable pointer events */
        userSelect: 'none',  /* Disable text selection */
        cursor: 'default', /* Set default cursor */
        opacity: 0.5 /* Optionally reduce opacity to indicate disabled state */
    },
    disableFilter: {
        userSelect: 'none',  /* Disable text selection */
        cursor: 'default', /* Set default cursor */
    },
    tableRow: {
        '&:hover': {
          boxShadow: '0 4px 9px rgba(0, 0, 0, 0.27)',
         // boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px'
        },
        '&:hover $actionButton': {
            display: 'table-cell !important',
            position: 'sticky !important',
            visibility: 'visible !important',
            right: '0px !important',
            background: 'linear-gradient(to right, #ffffff, #ffffff)',
            // width: '90px !important'
        }
    },
    inspectingTable: {
        borderCollapse: 'separate !important',
        borderSpacing: 'revert !important',
    },
    tableRowBoxShadow: {
        boxShadow: '0 0px 8px rgba(0, 0, 0, 0.2)'
    },
    tableContainer: {
        overflowX: 'hidden !important'
    },
    actionButton : {
        // display: 'none'
        visibility: 'hidden',
        display: 'block'
    },
    actionButtonSelected : {
        display: 'table-cell !important',
        position: 'sticky !important',
        right: '0 !important',
        background: 'linear-gradient(to right, #fefefe, #ffffff)'
    }
});


export default tableStyle;
